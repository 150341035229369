import { useState } from 'react';
import ProfileActions from './ProfileActions';

function useProfileState() {

  const [item, setItem] = useState(null)
  const [created_at, setCreatedAt] = useState(null)
  //const [list, setList] = useState(null)
  const [error, setError] = useState(null)

  /**
  const listLoadedHandler = (data) => {
    setList(data.data)
  } */
  const itemLoadedHandler = (data) => {

    setItem(data.data)

  }

  const itemCreatedHandler = (data) => {
  
    setItem(data.data)
    setCreatedAt(new Date())
  }


  const errorHandler = (data) => {
    setError(data)
  } 

  const getItem = (token) => {
    ProfileActions.getItem(token,  itemLoadedHandler, errorHandler)
  }

  const create = (token, item) => {
    ProfileActions.create(token, item, itemCreatedHandler, errorHandler)
  }

  /**
  const getList = (token, type = null, target_date = null , lat = null , lng = null , radius = null) => {
    SitterActions.getList(token, type, target_date, lat, lng, radius, listLoadedHandler, errorHandler)
  }

  const getMainImageFromMedias = (medias) => {
    if(medias)
      return medias.find(item => item.category === "main")
    else 
      return null
  }
   */
  return{ 
    profile:item,
    profile_created_at:created_at,
    //profile_list:list, 
    profile_error:error, 
   // getProfileList:getList,
    getProfile:getItem,
    createProfile:create
  };
}

export default useProfileState