import React, {} from 'react';
import config from 'react-global-configuration';
import "./pref.css"



export const PrefectureSelect = ({
  //current_page,
  selected_id,
  changeHandler,
  no_border,
  ...props
}) => { 

  const border_css = no_border ? "" : "border"
  return (
    <div className={"container pref_search w100 " + border_css}>
      <select   className="w100" onChange={changeHandler}>
      <option value={0}>都道府県を選択</option>
        {
          config.get("PREFS").map(item => {
            return <option  className="w100" value={Number(item.value)} selected={Number(item.value) === Number(selected_id)}>{item.label}</option>
          })
        }
      </select>
    </div>
  );
};



PrefectureSelect.propTypes = {
  selected_id:Number,
  changeHandler:Function,
  no_border:Boolean
}

PrefectureSelect.defaultProps = {
  selected_id:0,
  selectedHandler:function(){},
  no_border:false

};

