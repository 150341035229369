import React, { useState, useEffect } from 'react'
import {useParams} from 'react-router-dom';
import useAccoountState from './useAccoountState'

import Header from "../header/Header"
import { Footer } from '../footer/Footer';

import "../styles/form.css"
import "../styles/centerBox.css"

function AccountMailCheckApp(props) {

  const { mail } = useParams();
  const { secret } = useParams();
  const [message, setMessage] = useState("認証中")
  const {account,accountError,check} = useAccoountState()
  

  useEffect(() => {

    check(mail,secret)
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    
    if(account){
      window.location.href = "/user/create/check/success"
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[account]);


  useEffect(() => {
    
    if(accountError){
      setMessage(<div className='message'>登録エラーです。</div>)
    } 
   
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accountError]);


  return(

    <div className="top">
      <Header />
      <main  className="innner">
        <div className="center-box">
          <h1 >新規会員登録</h1>
          {message}
        </div>
        <Footer />
      </main>
    </div>



  )
}

export default AccountMailCheckApp
