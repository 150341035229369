
import Action from "../action/Action";
import config from 'react-global-configuration';
var PetActions = {

  getItem(token, pet_id, callback, errorHandler ){
    const url = config.get("API_PATH") + config.get("API_PET")  + "/" + pet_id
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },

  create(token, item,callback, errorHandler){

    const method = item.id ? "PATCH" : "POST"
    const url_id = item.id ? "/" + item.id : ""
    const url = config.get("API_PATH") + config.get("API_PET")   + url_id

    Action.fetchAuthorizedJson( url , token, method, {'Content-Type': 'application/json'},  JSON.stringify(item),  callback, errorHandler)
  }


  /**

  

  createImage(id, item){
 
    const token = AuthStore.getToken()
    const formData = new FormData();
    const localUri = item.uri;
    const filename = localUri.split('/').pop();

    // Infer the type of the image
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `image/${match[1]}` : `image`;

    // Assume "photo" is the name of the form field the server expects
    formData.append('file', { uri: localUri, name: filename, type });
    fetch(ConstantsEx.expoConfig.extra.API_PATH + ConstantsEx.expoConfig.extra.API_PET + "/" + id + "/medias", {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token
      },
      body: formData
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.PET_IMAGE_CREATED,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.PET_DETAIL_UPDATE,
      name:name,
      value:value
    })
  },

  createNew(params){
    AppDispatcher.dispatch({
      actionType: Constants.PET_CREATE_NEW,
      params:params
    })
  }
  **/

}

export default PetActions
