import React,{  } from 'react';
//import PropTypes from 'prop-types';
import Sitter from './Sitter';
import "./sitter.css"
export const SitterCard = ({
  sitter,
  type,
}) => {


  const clickHandler = () => {
    window.location.href = "/sitter/" + sitter.id
  }

  return(
    sitter ? 
    <div className="sitter-card" onClick={clickHandler}>
      <div className="image"><img src={sitter.medias[0].url} alt="プロフィール" /></div>
      {type === "simple" ? null :  <div className="comment">{sitter.profile.comment  ? sitter.profile.comment.substr(0,50) : null}...</div> }
      <div className="name">{sitter.profile.name}</div>
      <div className="address">{sitter.getAreaName()}{sitter.profile.address}</div>
    </div>
    :null
   )
}

SitterCard.propTypes = {
  sitter:Sitter,
  type:String
};

SitterCard.defaultProps = {
  sitter:{},
  type:"default"
};
