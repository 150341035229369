import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pet from './Pet.tsx';
import "./pet.css"
import config from 'react-global-configuration';
import usePetState from './usePetState.js';
import useMediaState from '../media/useMediaState.js';

/**
 * Primary UI component for user interaction
 */
export const PetItem = ({ token, pet, onClick, ...props }) => {
  const [pet_item, setPetItem] = useState(pet)
  const [message, setMessage] = useState(null)
  const [photo, setPhoto] = useState(null)
  const {loaded_pet, created_pet_at,createPet,getPet} = usePetState()
  const {media_updated_at, updatePetPhoto} = useMediaState()


  useEffect(() => {

    if(pet){
      setPetItem(pet)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pet]);


  useEffect(() => {
    
    if(media_updated_at){
      getPet(token,pet_item.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[media_updated_at]);


  useEffect(() => { 
    console.log(loaded_pet)
    if(created_pet_at){
      setMessage(<div className="message">更新されました</div>)
      if(loaded_pet)
        setPetItem(new Pet(loaded_pet))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[created_pet_at]);




  const changeHandler = (e) => {
    const target = e.target

    const new_item = pet_item.set(target.name, target.value)
    setPetItem(new_item)
  }

  const changeNumberHandler = (e) => {
    const target = e.target
    const new_item = pet_item.set(target.name, Number(target.value))
    setPetItem(new_item)
  }

  const changeFileHandler = (e) => {
    const target = e.target
    setPhoto(target.files[0])
  }

  const onClickHandler = () => {
    var error_message =  []
    setMessage(null)
   
    if(!pet_item.name) error_message.push("名前を入力してください")
    if(!pet_item.type) error_message.push("種類を入力してください")
    if(!pet_item.birthday) error_message.push("誕生日を入力してください")
    if(!pet_item.weight) error_message.push("体重を入力してください")
    if(!pet_item.gender) error_message.push("性別を入力してください")
    
    if(error_message.length === 0) {
      createPet(token,pet_item)


      if(photo){
        updatePetPhoto(token, photo, pet_item.id)
        setPhoto(null)
      }


    }else{
      setMessage(<div className="message">{error_message.map((item,index) => <div  key={"error_" + index}>{item}</div>)}</div>)
    }
    
  }

  return (
          <div className="formbox pet" id={"pettt" + pet ? pet.id: "0"} key={"pettt" + pet ? pet.id: "0"}>
           
              <div className="detail">
                <div className="card-item"><label>名前</label><div className="value"><input type="text" name="name" key={"name_" + pet_item.id} autocomplete="off" placeholder='' value={pet_item.name} onChange={changeHandler} /></div></div>
              </div>

              { pet && pet.id ?
              <div className="detail">
                <div className="card-item">
                  <label>写真</label>
                  <div className="value">
                    {
                      pet_item.photo && pet_item.photo.length > 0 ? <div className="photo-thum"><img src={pet_item.photo[0].url} alt="ペット" /></div> : null
                    }
                    <input type="file" accept="image/*" key={"notice_f" + pet_item.id}  name="media" placeholder='' onChange={changeFileHandler} />
                  </div>
               </div>
              </div>
            : null }


              <div className="detail">
                <div className="card-item"><label>種類</label>
                  <div className="value select" onChange={changeHandler} >
                      <select name="type">
                      <option value={0}>選択してください</option>
                        {config.get("PET_TYPE").map(item => {
                          return(<option selected={item.value === pet_item.type} value={item.value}>{item.label}</option>)
                        })}
                      </select>
                  </div>
                </div>
              </div>

              <div className="detail">
                <div className="card-item"><label>種類（詳細）</label><div className="value"><input type="text"  key={"type_detail_" + pet_item.id} name="type_detail" placeholder='' value={pet_item ? pet_item.type_detail : undefined} onChange={changeHandler} /></div></div>
              </div>


              <div className="detail">
                <div className="card-item"><label>誕生日</label><div className="value"><input type="date" name="birthday" key={"birthday_" + pet_item.id}  placeholder='' value={pet_item ? pet_item.birthday : undefined} onChange={changeHandler} /></div></div>
              </div>

              <div className="detail">
                <div className="card-item"><label>体重(kg)</label><div className="value"><input type="number" min={0} key={"weight_" + pet_item.id}  name="weight" placeholder='' value={pet_item ? pet_item.weight : undefined} onChange={changeNumberHandler} /></div></div>
              </div>


              <div className="detail">
                <div className="detail">
                  <div className="card-item"><label>性別</label>
                    <div className="value select">
                    
                        <select name="gender" onChange={changeNumberHandler} >
                        <option value={0}>選択してください</option>
                          {config.get("PET_GENDER").map(item => {
                            return(<option selected={Number(item.value) ===  Number(pet_item.gender)}  value={item.value}>{item.label}</option>)
                          })}
                        </select>
                    </div>
                  </div>
                </div>
              </div>


              <div className="detail">
                <div className="card-item"><label>性格・特徴</label><div className="value"><input type="text" key={"comment_" + pet_item.id}  name="comment" placeholder='' value={pet_item ? pet_item.comment : undefined} onChange={changeHandler} /></div></div>
              </div>

              <div className="detail">
                <div className="card-item"><label>注意点</label><div className="value"><input type="text" key={"notice_" + pet_item.id}  name="notice" placeholder='' value={pet_item ? pet_item.notice : undefined} onChange={changeHandler} /></div></div>
              </div>


              {message}
              <div className="btn-box" ><button onClick={onClickHandler} className="progress big">{pet_item && pet_item.id ? "更新" : "登録"}</button></div>

        </div>
  );
};

PetItem.propTypes = {
  token:PropTypes.string | null,
  pet:Pet,
 
  /**
   * Optional click handler
   */
  onClick: PropTypes.func |  undefined,
};

PetItem.defaultProps = {
  token:null,
  pet: new Pet(),
  onClick: {},
};
