import React from 'react';
import PropTypes from 'prop-types';
import LogoImage from "./svg/logo.svg"
import LogoWithImageImage from "./svg/logo_with_image.svg"
import './logo.css';
/**
 * Primary UI component for user interaction
 */
export const Logo = ({ type, width, onClick, ...props }) => {
  const image = type === "default" ? LogoImage : LogoWithImageImage;
  return (
    <div
      className={"logo"}
      style={{width:width ? width : 100}}
      {...props}
      onClick={onClick}
    >
     <img src={image} alt="Search" />
    </div>
  );
};

Logo.propTypes = {

  type: PropTypes.oneOf(['default', 'image']),
  width: PropTypes.number,
  onClick: PropTypes.func,
};


Logo.defaultProps = {
  type: "default",
  width: null,
  onClick: undefined
};
