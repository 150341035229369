import React, { useState, useEffect } from 'react'
import {APIProvider, Map, Marker, AdvancedMarker, Pin} from '@vis.gl/react-google-maps';


function GoogleMap(props) {


  const [marks, setMarks] = useState([])
  const API_KEY = "AIzaSyAXx8CXdy2OcpRDih-kDyTEq8j1NepMcm8"

  const containerStyle = {
    width: '100%',
    height: '600px'
  };


  const [center,setCenter] = useState(null)
  useEffect(() => {
    if(props.marks){

      const marks =  props.marks.map( mark => {

        if(mark.lat && mark.lng){
          const _mark = mark
          return  <AdvancedMarker position={{lat:mark.lat, lng:mark.lng}} title={mark.id} clickable={true}  onClick={() => onClickHandler(_mark)} >
            <Pin
      background={'#ff00ff'}
      borderColor={'#ff00ff'}
      glyphColor={'#ffffff'}
    /></AdvancedMarker>
        }else{
          return null
        }
        
      })
      marks.push(<Marker position={props.position}  clickable={false} />)
      setMarks(marks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.marks]);


  useEffect(() => {
    setCenter(props.position)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.position]);

  const onClickHandler  = (e) => {
    if(props.onClickHandler){
      props.onClickHandler(e) 
    }
  }    /**
  function getMapCenter(){


    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: props.address }, ( results, status ) => {
      if (status === 'OK') {
        
        let center = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        };
        setCenter(center)
        props.updateForm("lat", results[0].geometry.location.lat())
        props.updateForm("lng", results[0].geometry.location.lng())
      }
    });
    
  }*/
  return(
    <div >

   
        {
          center ? 
          <APIProvider apiKey={API_KEY} libraries={['marker']}>
            <Map
              mapId={'bf51a910020fa25a'}
              style={containerStyle}
              defaultCenter={center}
              defaultZoom={16}
              gestureHandling={'greedy'}
              disableDefaultUI
            > 
              
              {
                marks
              }
             
              </Map>
          </APIProvider>
          : null
        }
        

    </div >
  )
}

export default GoogleMap
