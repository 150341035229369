import React from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
//import IndexApp from "./components/IndexApp"
import HomeApp from './home/HomeApp.js';
import MapApp from './map/MapApp.js';
import CompanyApp from './static_pages/CompanyApp.js';
import RegurationApp from './static_pages/RegurationApp.js';
import PrivacyApp from './static_pages/PrivacyApp.js';
import EcApp  from './static_pages/EcApp.js';
import AboutApp from './static_pages/AboutApp.js';
import UsageApp from './static_pages/UsageApp.js';
import NoticeApp from './static_pages/NoticeApp.js';
import FAQApp from './static_pages/FAQApp.js';
import SitterListApp from './sitter/SitterListApp.js';
import BookCreateApp from './book/BookCreateApp.js';
import LoginApp from './login/LoginApp.js';
import SitterDetailApp from './sitter/SitterDetailApp.js';

import BookListApp from './book/BookListApp.js';
import BookApp from './book/BookApp.js';

import MenuApp from './menu/MenuApp.js';

import AccountCreateApp from './account/AccountCreateApp.js';
import AccoutCreateSuccessApp from './account/AccoutCreateSuccessApp.js';
import AccountMailCheckApp from './account/AccountMailCheckApp.js';
import AccountMailCheckSuccessApp from './account/AccountMailCheckSuccessApp.js';

import ProfileApp from './profile/ProfileApp.js';
import PetApp from './pet/PetApp.js';
config.set(configuration);

class App extends React.Component{


render(){
return(
    <div>
      <Routes>
        <Route  path='/' element={<HomeApp />} />
        <Route  path='/home' element={<HomeApp />} />
        <Route  path='/login' element={<LoginApp />} />
        <Route  path='/sitter' element={<SitterListApp />} />
        <Route  path='/sitter/:sitter_id' element={<SitterDetailApp />} />
        <Route  path='/book/create/:sitter_id/:target_date/:hour' element={<BookCreateApp />} />
        <Route  path="/book/:book_id" element={<BookApp />} />
        <Route  path='/map' element={<MapApp />} />
        <Route  path='/search' element={<MapApp />} />
        <Route  path='/mypage' element={<BookListApp />} />
        <Route  path='/books' element={<BookListApp />} />
        <Route  path='/profile' element={<ProfileApp />} />
        <Route  path='/pet' element={<PetApp />} />
        <Route  path='/menu' element={<MenuApp />} />
        <Route  path='/user/create/check/success' element={<AccountMailCheckSuccessApp />} />
        <Route  path='/user/create/success' element={<AccoutCreateSuccessApp />} />
        <Route  path='/user/create' element={<AccountCreateApp />} />
        <Route  path='/accounts/:mail/:secret' element={<AccountMailCheckApp />} />
        


        <Route  path='/about' element={<AboutApp />} />
        <Route  path='/use' element={<UsageApp />} />
        <Route  path='/notice' element={<NoticeApp />} />
        <Route  path='/faq' element={<FAQApp />} />
        <Route  path='/company' element={<CompanyApp />} />
        <Route  path='/regulation' element={<RegurationApp />} />
        <Route  path='/privacy' element={<PrivacyApp />} />
        <Route  path='/ec' element={<EcApp />} />
      </Routes>
    </div>
)}}

const container = document.getElementById('root')

if(container){
  const root = createRoot(container)
  root.render((<BrowserRouter ><App /></BrowserRouter>))
}


