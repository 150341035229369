import React from 'react';
import PropTypes from 'prop-types';
import './CloseBtn.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';

/**
 * Primary UI component for user interaction
 */
export const CloseBtn = ({ type, onClick, ...props }) => {
  const className = type === "default" ? "closebtn" : "closebtn ";
  return (
    <div
      className={className}
      {...props}
    >
      <div className="closeBox" onClick={onClick ? onClick : () => {}}>
        <FontAwesomeIcon icon={faXmark} />
      </div>
    </div>
  );
};

CloseBtn.propTypes = {

  type: PropTypes.oneOf(['default', 'white']),
 
  /**
   * Optional click handler
   */
  onClick: PropTypes.func |  undefined,
};

CloseBtn.defaultProps = {
  type: "default",
  onClick: undefined,
};
