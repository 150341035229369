import { Record } from 'immutable'


const  SitterProps = Record({
  id:null,
  account_id:null,
  book_number:null,
  created_at:null,
  favorite : [],
  medias :[],
  profile  : null,
  rate:0
});
export default SitterProps;


