import React, {  } from 'react';
import { Logo } from '../stories/logo/Logo';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import "./page.css"
function EcApp(props:PropsValue) {

   



  return(
    <div className="top">
      <Header />
      <main className="full">
        <div className="home_logo static">
          <Logo width={100} type="image" />
        </div>
        <div className="white">
          <div className="content">
            <h1>特定商取引法に基づく表記</h1>
            <div  className="question">
              <div className="question-item">
                <div className="label">運営社名</div>
                <div className="item-content">
              株式会社YOKUSURU
                </div>
              </div>

              <div className="question-item">
                <div className="label">URL</div>
                <div className="item-content">
                https://petcare.co.jp/
                </div>
              </div>

              <div className="question-item">
                <div className="label">代表者</div>
                <div className="item-content">
                楠瀬 健太
                </div>
              </div>

              <div className="question-item">
                <div className="label">電話番号</div>
                <div className="item-content">
                050-3662-3413
                </div>
              </div>

              
              <div className="question-item">
                <div className="label">本社所在地</div>
                <div className="item-content">
                東京都中央区銀座1丁目12番4号N&E BLD.6F
                </div>
              </div>

              <div className="question-item">
                <div className="label">お支払い方法</div>
                <div className="item-content">
                クレジットカード決済
                </div>
              </div>
              <div className="question-item">
                <div className="label">代金の支払い時期</div>
                <div className="item-content">
                クレジットカードによるご利用毎の都度払い（預かりサービス終了後のお支払い）
                </div>
              </div>
              <div className="question-item">
                <div className="label">商品代金以外に必要な費用</div>
                <div className="item-content">
                シッター交通費、システム利用時のサービス手数料(税込)、インターネット接続料金、通信料金等
                </div>
              </div>

              <div className="question-item">
                <div className="label">商品の引き渡し時期</div>
                <div className="item-content">
                飼い主: 本サービス上で予約成立後、予約内容に従い動物預かりサービスが提供されます。<br/>
シッター: 本サービス上への登録が完了し、弊社基準での審査完了後、シッター一覧に掲載され、その後動物預かり依頼の受注等の本サービスが利用可能な状態となります。
                </div>
              </div>

              <div className="question-item">
                <div className="label">キャンセル・解約について</div>
                <div className="item-content">
                飼い主: 本サービス上で予約成立後、予約内容に従い動物預かりサービスが提供されます。<br/>
                商品特性上、返品・交換は不可能です。キャンセルについては規約に規定あり。
                </div>
              </div>


              <div className="question-item">
                <div className="label">不良品の取扱条件</div>
                <div className="item-content">
                商品の特性上、不良品という概念は設けません。
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default EcApp
