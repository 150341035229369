

import React, {  useState, useEffect } from 'react'

import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import { SideMenu } from '../menu/mypage/SideMenu.jsx';
import useAuthState from '../auth/useAuthState';
import Book from './Book.tsx';
import useBookState from './useBookState.js';
import "./book.css"
import { BookCardForScheduled } from './BookCardForScheduled.jsx';


function BookListApp(props:PropsValue) {

  const [books, setBooks] = useState(null)
  const {token, getToken} = useAuthState()

  const {book_list, updated_book_at,  getScheduledBookList} = useBookState()




  useEffect(() => {
    if(updated_book_at){
      const _books = book_list.map(item => new Book(item))
      setBooks(_books)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updated_book_at]);

  useEffect(() => {
    if(token){
      getScheduledBookList(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);



  const authHandler = (_t) =>{
    getToken()
  }


  return(
    <div  className="top">
      <Header is_auth_required={true} authHandler={authHandler}/>
      <main  className="innner">
      <div className="detail-wrap book-detail">
        <div className="detail-left detail">
          <SideMenu selcted="books" />
        </div>
        <div className="detail-right">
          <div className="book-detail-info">
            <h2>今後の予約</h2>
              <div className="book-list">
                {books ? books.map(book => <BookCardForScheduled book={book} />) : null}
              </div>
          </div>
        </div>
      </div>
      <Footer />
      </main>
    </div>    
  )
}

export default BookListApp

