import React, {  } from 'react';
import { Logo } from '../stories/logo/Logo';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import "./page.css"
function PrivacyApp(props:PropsValue) {

  

  return(
    <div className="top">
      <Header />
      <main className="full">
        <div className="home_logo static">
          <Logo width={100} type="image" />
        </div>
        <div className="white">
          <div className="content">
            <h1>プライバシーポリシー</h1>
           

            <h2>１　はじめに</h2>
<p>株式会社YOKUSURU（以下、「当社」といいます。）は、お客様が本件サービスを安心して利用いただけますように、個人情報（お名前、メールアドレス、並びにアカウント及びパスワードなど個人を識別する情報）を個人の重要な財産と認識し、本プライバシーポリシーに則り、お客様から提供される個人情報を厳重かつ大切に管理いたします。
</p>

<h2>２　基本方針</h2>
<p>
（１）個人情報の収集は、常に利用目的を明示し、本人の同意を得た上で行い、その利用目的のために必要な範囲を超えて個人情報を取り扱うことはありません。<br/>
（２）収集した個人情報の取扱において、技術的・組織的に総合的なセキュリティ対策を講じ、適切な管理の下、個人情報を保存します。<br/>
（３）日本国における個人情報の保護に関する法令及びその他の規範を遵守いたします。
</p>
<h2>３　個人情報の取得</h2>
<p>
（１）本件サービスのご登録手続きに際し、個人認証のため、個人情報の提供をお願いしています。一部のサービスでは、クレジットカードその他のお支払いに関するアカウント情報の提供をいただく可能性がありますが、これらの情報は安全なサーバー上で暗号化して管理いたします。<br/>
（２）お客様のアカウントに含まれる情報を第三者から取得した情報と統合し、お客様の利便性の向上および本サービスの品質向上のために使用する場合もあります。予めご了承ください。
</p>
<h2>４　利用目的</h2>
<p>
当社は、個人情報をお預かりする場合、以下に定める利用目的及びお問い合わせの窓口等を示した上、その取り扱いについては、目的達成に必要な範囲においてのみ利用します。<br/>
（１）当社サービスの利用時における個人認証、サービス画面上の氏名等の表示のため<br/>
（２）当社または当社サービス利用企業の賞品発送のため<br/>
（３）メンテナンス、重要なお知らせ等のご案内送付のため<br/>
（４）当社サービスの広告・宣伝等の通知のため<br/>
（５）各種問い合わせ対応及びアフターサービスのため<br/>
（６）マーケティングデータの分析及び新サービス等の開発のため<br/>
（７）メルマガ等の配信のため
</p>
<h2>５　第三者提供</h2>
<p>
当社は、提供いただいた個人情報を以下の各号に該当する場合を除き、いかなる第三者にも提供または開示いたしません。<br/>
（１）本人の同意がある場合<br/>
（２）第三者に対し当社が本件サービスを提供するために必要な業務を委託する場合（ただし、委託先などに対し、当社が委託したサービス以外に個人情報を利用することがないようあらかじめ契約を結んでいる場合に限る）<br/>
（３）法令に基づく手続きを経て、提出や閲覧の要請があった場合<br/>
</p>
<h2>６　個人情報の開示、訂正及び削除</h2>
<p>
お客からの個人情報の開示、削除、利用停止及び第三者提供の停止の請求に対しては、合理的な範囲で対応いたします。但し、下記に該当する場合にはご希望に添えない場合があります。<br/>
（１）ご本人確認ができない場合<br/>
（２） 請求を受けた情報が存在しない場合<br/>
（３） 情報を開示することにより、ご本人又は第三者の生命・財産・身体その他の権利利益を害するおそれがある場合<br/>
（４） 情報を開示することが法令違反になる場合
</p>
<h2>７　クッキーについて</h2>
<p>
当社サービスでは、お客の利便性を高めるためクッキー（Cookie）と呼ばれる技術を使用しており、クッキーの利用・許可を前提としたサービスを提供しています。クッキーとは、特定の情報をお客様の利用する通信機器に一時的にデータとして保持させ、接続のたびにそのデータを元にお客様を識別する仕組みです。クッキーの利用を許可しない設定をされた場合に、当社サービスの一部または全てをご利用できない場合があります。なお、クッキーに関する設定方法はお使いのブラウザの「ヘルプ」メニュー等でご確認ください。
</p>
<h2>８　個人情報保護方針の改定</h2>
<p>
当社は、本プライバシーポリシーを定期的に見直しております。そのため、本ポリシーの全部または一部を改定することがあります。重要な変更がある場合には、当サイトのページ上において適宜ご案内するとともに、改定した内容はこのページに更新し、いつでも閲覧可能な状態とします。
</p>
<h2>９　お問い合わせ先</h2>
<p>
個人情報の取扱に関する苦情、取り合わせについては、下記までお申し出ください。<br/>
・住所　　　　　　：東京都中央区銀座1丁目12番4号N&E BLD.６F<br/>
・担当部署　　　　：個人情報相談窓口<br/>
・メールアドレス　：info@yokusuru.co
</p>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default PrivacyApp
