import { Record } from 'immutable'

const BookProps = Record({
  id:null,
  sitter_id:null,
  name: null,
  start_at: null,
  end_at:null,
  pets:[],
  sitter:null,
  message:null,
});
export default BookProps;
