

import React, {  useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';


import { format, setHours } from 'date-fns';


import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import useAuthState from '../auth/useAuthState';
import Book from './Book.tsx';
import Sitter from '../sitter/Sitter';
import useSitterState  from '../sitter/useSitterState';
import useProfileState from '../profile/useProfileState';
import { SitterCard } from '../sitter/SitterCard.jsx';
import { BookCard } from './BookCard.jsx';
import useBookState from './useBookState.js';
import "./book.css"


function BookCreateApp(props:PropsValue) {

  const { sitter_id } = useParams()
  const { target_date } = useParams()
  const { hour} = useParams()

  const date = setHours(new Date(target_date), Number(hour))

  const [book, setBook] = useState(new Book({sitter_id:sitter_id, start_at:date}))
  const [message, setMessage] = useState(null)
  const {token, getToken} = useAuthState()
  const {sitter, getSitter} = useSitterState()
  const {profile, getProfile} = useProfileState()
  const [book_updated, setBookUpdated] = useState(null)
  const {created_book_at,book_error, createBook} = useBookState()


  useEffect(() => {
    if(profile){
      const _book = book.set("name", profile.name)
      setBook(_book)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);
  
  useEffect(() => {
    getSitter(null, sitter_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(token){
      getProfile(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  

  useEffect(() => {
    if(created_book_at){
      window.location.href = "/books"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[created_book_at]);


  useEffect(() => {
    if(book_error){
      setMessage("予約ができませんでした。")
    }
    
  },[book_error]);

  const authHandler = (_t) =>{
    getToken()
  }


  const changeHandler = (name, value) => {
    var new_book = book.set(name, value)
    setBook(new_book)
    setBookUpdated(new Date())
  }



  const send = () => {
    setMessage(null)
    if(book.pets.length === 0){
      setMessage("ペットを指定してください")
      return false
    }

    if(!book.message || book.message === ""){
      setMessage("依頼内容を記入してください")
      return false
    }

    createBook(token, book)
  }


  return(
    <div  className="top">
      <Header is_auth_required={true} authHandler={authHandler}/>
      <main  className="innner">
      <div className="detail-wrap book-detail">
        <div className="detail-left detail">
          <div className="book-date-box">
            <h2>予約日時</h2>
            <div className="book-date">{format(date, "yyyy年MM月dd日 H時")}</div>
          </div>
           <div className="book-date-box">
            <h2>シッター情報</h2>
            {sitter ? <SitterCard sitter={new Sitter(sitter)} type="simple" /> : null}
          </div>
        </div>
        <div className="detail-right">
          < div className="book-detail-info">
            <h2>予約詳細情報</h2>
            <div className="book-date">
              {book ? <BookCard book={book} updated={book_updated} changeHandler={changeHandler} card_type="edit" pets={profile ? profile.pets : null}/> : null}
            </div>
          </div>
          <div className='message red'>{message}</div>
            <div className="btn-box" ><button onClick={send} className="progress big">予約リクエスト</button></div>

          </div>
      </div>

      <Footer />
      </main>
    </div>    
  )
}

export default BookCreateApp


/**  <Box style={styles.mainContainer}>
       <Header title="予約リクエスト" navigation={props.navigation} />
       <ScrollView mt="60px" w="100%" scrollIndicatorInsets={{ right: 1 }}>
          <KeyboardAvoidingView  behavior="padding">         
          
          <VStack space={2}  p="20px" width="100%" backgroundColor="#ffffff">

            <BookFormItem changeHandler={changeHandler} label="予約希望の日にちは" params={
              [{label:"日付", readonly:true,value:format(target_date, "yyyy年M月d日")}]
            }/>
            <Flex flexWrap="wrap" ml={"auto"} mr={"auto"} p={0} width="100%" >
              <Flex flexWrap="wrap" direction="row" justifyContent="flex-start" mb="2.5" mt="1.5" width="100%" >
                {times}
              </Flex>
            </Flex>
            <BookFormItem changeHandler={changeHandler} label="ペットシッター" params={
              [{label:"名前", readonly:true, value:sitter ? sitter.profile.name : null}]
            }/>

          
            <BookFormItem changeHandler={changeHandler} label="お預けのペットは？" params={[
              {label:null, readonly:false,value:item ? item.pets : [], name:"pets", type:"checkbox", selection:pets}
            ]}/>
            
            <BookFormItem  changeHandler={changeHandler} label="依頼する内容を教えてください" params={[
              {label:null, readonly:false,value:item ? item.message : null, name:"message", type:"textarea"}
            ]}/>
            {alert}
            <Box w={"100%"}>
              <Button mt={2} style={styles.buttonBlue} onPress={() => send()}>予約する</Button>
            </Box>
         </VStack> 
        
        </KeyboardAvoidingView>
        </ScrollView>
        </Box>
         */