

import React, { useEffect , useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import Sitter from './Sitter';
import "./sitter.css"
import useSitterState from '../sitter/useSitterState';
import { BookCalendar } from './BookCalendar';
import { TimeSheet } from './TimeSheet';
import Utile from '../utiles/Utile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

function SitterDetailApp(props:PropsValue) {
  const token = null
  const { sitter_id } = useParams();
  const [item, setItem] = useState(null)
  const [selected_day, setSelectedDay] = useState(null)
  const {sitter, getSitter} = useSitterState()
  
 

  useEffect(() => {
    if(sitter){
      const _item = new Sitter(sitter)
      setItem(_item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sitter]);

  useEffect(() => {
    getSitter(token, sitter_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);





  const selectDate = (e) => {
    setSelectedDay(e)
  }


  return(
    <div className="top">
    <Header />
    <main  className="innner">
    <div className="detail-wrap sitter-detail">
     
      <div className="detail">

        { item  && item.medias.length > 0 ? 
          <div className="sitter-image">
          <img
            src={item.medias[0].url}
            alt="シッター画像"
          />
          </div>
          : null
        }
        <h1 key="title">{sitter ? sitter.profile.name : null}</h1>
        <div>{sitter ? Utile.nl2br(sitter.profile.comment)  : null}</div>
      
      </div>
      <div className="calendar">
        {item ? <BookCalendar token={token} sitter={item} moveHandler={selectDate}/> : null}
        <div className="select-day">
        {selected_day 
        ? <TimeSheet sitter={item} target_date={selected_day}/>
        : <div className="sml"><FontAwesomeIcon icon={faCircleExclamation} /> 予約希望日をクリックしてください。</div>
        }
        </div>
        
      </div>
    </div>
    
      <Footer />
    </main>
  </div>
  )
}

export default SitterDetailApp

