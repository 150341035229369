import { useState } from 'react';
import AccountAction from './AccountAction';


function useAccoountState() {

  const [item, setItem] = useState(null)
  const [error, setError] = useState(null)


  const completedHandler = (data) => {
    setItem(data.data)
  }


  const errorHandler = (data) => {

    setError(data)
  }

  const check = (mail,secret) => {
    AccountAction.check(mail, secret, completedHandler, errorHandler);
  }; 
  const create = (username, password) => {
    AccountAction.create(username, password, completedHandler, errorHandler);
  };  




  return { account:item, accountError:error, createAccount:create, check};
}

export default useAccoountState