

import React, { useEffect , useState } from 'react';

import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import useAuthState from '../auth/useAuthState';
import useProfileState from '../profile/useProfileState';
import { SideMenu } from '../menu/mypage/SideMenu.jsx';
import {AddLeftBtn} from "../stories/add_left_btn/AddLeftBtn.jsx";
import "./pet.css"

import { PetItem } from './PetItem.jsx';
import Pet from './Pet.tsx';
function PetApp(props:PropsValue) {

  const [item, setItem] = useState([])


  const {token, getToken} = useAuthState()
  const {profile, getProfile} = useProfileState()
 

  useEffect(() => {
    if(profile){
     if(profile.pets.length === 0){
      setItem([new Pet()])
     }else{
      setItem(profile.pets.map(item => new Pet(item)))
     }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);
  

  useEffect(() => {
    if(token){
      getProfile(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);




  



  const authHandler = (_t) =>{
    getToken()
  }

  const addNew = () => {
    item.push(new Pet())
    setItem(item)
  }

  const send = () => {

  }


  return(
    <div className="top">
    <Header is_auth_required={true} authHandler={authHandler} />
    <main  className="innner">
      <div className="detail-wrap user-detail">     
        <div className="detail-left detail">
          <SideMenu selcted="pet" />
        </div>
        <div className="detail-right">
          <div className="main-white-box">
            <h1>ペット</h1>
            <AddLeftBtn label="ペット追加" onClick={addNew} />
            { item.reverse().map((_pet, index) => {
              return <div><PetItem onClick={send} token={token} pet={_pet} key={"pet_" + index}/></div>
            })}
           
          
          
          </div>
        </div>
    </div>
    
      <Footer />
    </main>
  </div>
  )
}

export default PetApp
