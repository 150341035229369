import React, { useState, useEffect } from 'react'
import {format,subMonths,addMonths,addDays, getDay, setDate, getWeeksInMonth, isAfter, subDays} from 'date-fns';
import useBookState from '../book/useBookState';
import Sitter from './Sitter';
import "./calendar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
interface BookCalendarProps {
  sitter:Sitter,
  moveHandler:Function
}

/**
* Primary UI component for user interaction
*/
export const BookCalendar = ({
  sitter,
  moveHandler,
  ...props
}: BookCalendarProps) => { 

  const [current_month, setCurrentMonth] = useState(new Date())
  const [calendar_items, setCalendarItems] = useState(null)
  const {book_list, updated_book_at, getBookList, getRestByTargetDateFromList} = useBookState()


  useEffect(() => {
    if(book_list){

      const target_date = current_month
      
      const first_day = setDate(target_date, 1)
      const day_of_week = getDay(first_day)

      const week_num = getWeeksInMonth(first_day)
      const target_first_date = subDays(first_day, day_of_week)
      const new_array = (new Array(week_num)).fill(null).map((n,i) => {
          return (
            <tr key={"date_" + i}>
              {(new Array(7)).fill(null).map( (t, t_index) => {
                return createtTr(addDays(target_first_date, i * 7 + t_index ) )
              })}
            </tr> )
        })
      setCalendarItems(new_array)
    }

   // eslint-disable-next-line react-hooks/exhaustive-deps
 },[updated_book_at])

 
 useEffect(() => {
    //予約の取得　日付が変われば取得
    getBookList(sitter.id, current_month, addMonths(current_month,1))
   // eslint-disable-next-line react-hooks/exhaustive-deps
 },[current_month])


 const createBook  = (target_date,is_active) => {
  if(is_active){
    moveHandler(target_date)
  }
 }

 const createtTr = (target_day ) => {
  if(!target_day){
    return null
  }
  const today = new Date()
  const is_after = isAfter(target_day, today)
  var text  = null
  var is_active = true
  
  if(!is_after ){
    text = <div>-</div>
    is_active = false
  }else {
    const rest = getRestByTargetDateFromList(book_list, target_day);
    const open_time = sitter.getOpenTimeStr(target_day)
    if(rest){
   
      is_active = false
      text = <div>{rest.name}</div>
    }else if(open_time){
      text = <div>{sitter.getOpenTimeStr(target_day)}</div>
    }else{
    
      is_active = false
      text = <div>定休日</div>
    }
  }
  return(
    <td  key={"calendar_" + format(target_day, 'yyyy_MM_dd')} onClick={() => createBook(target_day,is_active)} className={is_active ? "active" : null}>
        
        <div>{format(target_day, 'd')}</div>
        <div className="text">{text}</div>
        
    </td>
  )
}


 const addMonth = ()=> {
  const new_date = addMonths(current_month, 1)
  setCurrentMonth(new_date)
}
const subMonth = ()=> {
  const new_date = subMonths(current_month, 1)
  setCurrentMonth(new_date)
}



 return (
  <div>
    <div className="book-calendar">
      <div className="book-calendar-header">
        <div onClick={subMonth}><FontAwesomeIcon icon={faAngleLeft} /></div>
        <div>{current_month ? format(current_month, "yyyy年M月") : null }</div>
        <div onClick={addMonth}><FontAwesomeIcon icon={faAngleRight} /></div>
      </div>
    </div>
  
    <div className="book-calendar body">
     <table>
      <tbody>
      <tr>
        <th>日</th>
        <th>月</th>
        <th>火</th>
        <th>水</th>
        <th>木</th>
        <th>金</th>
        <th>土</th>
        </tr>
        {calendar_items}
        </tbody>
      </table>
      
    </div>
  </div>
 );
};

