
import SitterProps from './SitterProps.tsx'
import {getDay, } from "date-fns"
import Utile from "../utiles/Utile"
import config from 'react-global-configuration';

const props = extendValues => class extends SitterProps {};


class Sitter extends props({}) {

  getOpenStartTime(target_date:Date){
 
    switch(getDay(target_date)){
      case 0: 
        return this.profile.sunday_start    
      case 1 :
        return this.profile.monday_start
      case 2 :
        return this.profile.tuesday_start
      case 3 :
        return this.profile.wednesday_start
      case 4 :
        return this.profile.thursday_start
      case 5 :
        return this.profile.friday_start
      case 6 :
        return this.profile.saturday_start
      default:
        return null
    }
  }

  getAreaName(){
    return Utile.getLabelByValue(config.get("PREFS"), this.profile.area_code)
  }

  getOpenEndTime(target_date:Date){ 
    switch(getDay(target_date)){
      case 0: 
        return this.profile.sunday_end    
      case 1 :
        return this.profile.monday_end
      case 2 :
        return this.profile.tuesday_end
      case 3 :
        return this.profile.wednesday_end
      case 4 :
        return this.profile.thursday_end
      case 5 :
        return this.profile.friday_end
      case 6 :
        return this.profile.saturday_end
      default:
        return null
    }
  }

  getOpenTimeStr(target_date:Date){
    switch(getDay(target_date)){
      case 0: 
        if(this.profile.sunday_start && this.profile.sunday_end){
          return Utile.getHourByFromAddMinuets(this.profile.sunday_start) + " 〜 " + Utile.getHourByFromAddMinuets(this.profile.sunday_end)
        }else{
          return null
        }
      
      case 1 :
        if(this.profile.monday_start && this.profile.monday_end){
          return Utile.getHourByFromAddMinuets(this.profile.monday_start) + " 〜 " + Utile.getHourByFromAddMinuets(this.profile.monday_end)
        }else{
          return null
        }
      
      case 2 :
        if(this.profile.tuesday_start && this.profile.tuesday_end){
          return Utile.getHourByFromAddMinuets(this.profile.tuesday_start) + " 〜 " + Utile.getHourByFromAddMinuets(this.profile.tuesday_end)
        }else{
          return null
        }
      
      case 3 :
        if(this.profile.wednesday_start && this.profile.wednesday_end){
          return Utile.getHourByFromAddMinuets(this.profile.wednesday_start) + " 〜 " + Utile.getHourByFromAddMinuets(this.profile.wednesday_end)
        }else{
          return null
        }
      
      case 4 :
        if(this.profile.thursday_start && this.profile.thursday_end){
          return Utile.getHourByFromAddMinuets(this.profile.thursday_start) + " 〜 " + Utile.getHourByFromAddMinuets(this.profile.thursday_end)
        }else{
          return null
        }
      
      case 5 :
        if(this.profile.friday_start && this.profile.friday_end){
          return Utile.getHourByFromAddMinuets(this.profile.friday_start)+ " 〜 " + Utile.getHourByFromAddMinuets(this.profile.friday_end)
        }else{
          return null
        }
      
      case 6 :
        if(this.profile.saturday_start && this.profile.saturday_end){
          return Utile.getHourByFromAddMinuets(this.profile.saturday_start) + " 〜 " + Utile.getHourByFromAddMinuets(this.profile.saturday_end)
        }else{
          return null
        }
      
      default:
        return "-"

    }
   
  }
}

export default Sitter
