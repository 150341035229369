import React from 'react'
import {  format,  addMinutes } from 'date-fns'
const Utile = {

  getLabelByValue: function(list,value){

    const result = list.filter(item => item.value === value) 
    if(result.length > 0) return result[0].label
    return null
  },

  getObjectByValue: function(list,value){

    const result = list.filter(item => item.value === value) 

    if(result.length > 0) return result[0]
    return null
  },

  nl2br: function(text) {
    if(text === null) return text
    let regex = /(\n)/g
    const tex = text.split(regex).map((line, i) => {
        if (line.match(regex)) {
            return (<br key={"b" + i} />)
        }else {
            return <span key={"l" + i}>{line}</span>
        }
    });
    return tex
  },

  
  getWeekString: function(target_day){
    switch(target_day.getDay()) {
      case 0 : return "日"
      case 1 : return "月"
      case 2 : return "火"
      case 3 : return "水"
      case 4 : return "木"
      case 5 : return "金"
      case 6 : return "土"
      default: return null
    }
  },

  getHourByFromAddMinuets: function(minites){
    const _day = addMinutes(new Date('2000-01-01 00:00:00'),minites)
    if(_day){
      return format(_day, "H:mm")
    }
    return ""
  }

  
}

export default Utile
