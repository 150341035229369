
import PetProps from './PetProps';


const props = extendValues => class extends PetProps {};
class Pet extends props({}) {

  /**getLatestReportMonthDate(){
    const today = new Date()
    return today
  }**/

}
export default Pet
