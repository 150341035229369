import Action from "../action/Action";
import config from 'react-global-configuration';

var ProfileActions = {

  getItem(token, callback, errorHandler ){
    const url = config.get("API_PATH") + config.get("API_PROFILE") 
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },

  create(token, item, callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = config.get("API_PATH") + config.get("API_PROFILE") 
    Action.fetchAuthorizedJson( url , token, method, {'Content-Type': 'application/json'},  JSON.stringify(item),  callback, errorHandler)
  },

  
  /** 
  createImage(photo){

    const token = AuthStore.getToken()
    const formData = new FormData();

    let localUri = photo.uri;
    let filename = localUri.split('/').pop();
  
    // Infer the type of the image
    let match = /\.(\w+)$/.exec(filename);
    let type = match ? `image/${match[1]}` : `image`;


    formData.append('file', { uri: localUri,  name: filename, type:type });
    console.log(formData)
    fetch(config.get("API_PATH")  + config.get("API_PROFILE") + "/medias", {
      method: "POST",
      headers: {
          
          'Authorization': 'Bearer ' + token
      },
      body: formData,
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 403  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
        }
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
    
      if(data && !data.error){
      
        ProfileActions.getItem(token, false)
        
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error.code
        })
      }
    })
  },


  updateForm(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.PROFILE_FORM_UPDATE,
      name:name,
      value:value
    })
  },

  createNew(params){
    AppDispatcher.dispatch({
      actionType: Constants.PROFILE_CREATE_NEW,
      params:params
    })
  },
   */



}

export default ProfileActions
