import React, {  } from 'react';
import { Logo } from '../stories/logo/Logo';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import "./page.css"
function AboutApp(props:PropsValue) {

   



  return(
    <div className="top">
      <Header />
      <main className="full">
        <div className="home_logo static">
          <Logo width={100} type="image" />
        </div>
        <div className="white">
          <div className="content">
            <h1>Petcareとは</h1>
            <div  className="about">
            <p>Petcareは有資格者100%のペットシッターマッチングサービスです。</p>
            <p>ペットシッターのプロフィールやサービス内容・料金から、“うちの子”にぴったりなペットシッターを検索・予約できます。</p>

            <p>ペットに関しては様々なお悩みがあります。仕事や旅行で外出するためペットの面倒がみれない、病気や怪我で散歩に連れて行けない、預けたいのにペットホテルがみつからない、毎回違う人がきてその度に教えるのが大変など・・・。</p>

            <p>Petcareは、ペットの生活環境を変えることなくご自宅で飼い主さまの代わりにわんちゃん・ねこちゃん・小動物のお世話をします。動物関連有資格者のシッターが、ごはんの用意、トイレ・毛の掃除、お散歩などのお世話全般を担当するため、安心してお任せください。ペットのストレスを最小限にできるよう「大切なご家族」を第一に考えたサービスをご提供します。</p>


            <p>「うちの子退屈していないかな？」「お留守番さみしがっていないかな？」「運動足りているかな？」「トレーナーさん頼もうかな？」と、“うちの子”を気にするすべてのペットオーナーとペットをサポートします。 アプリ、WEBページから会員登録後、ご利用頂けます。</p>

            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default AboutApp
