import React,{  } from 'react';
import PropTypes from 'prop-types';
import useAuthState from '../../auth/useAuthState';
import "./mypagemenu.css"
export const SideMenu = ({
  selcted,
  is_mobile
}) => {

  const { logout}  = useAuthState()

  const logoutHandler = ( ) => {
    logout()
    window.location.href = "/login"
  }




  return(
    <div className="sidemenu-warp">
    <h2>マイページ</h2>
      <ul>
        <li><a href="/books">今後の予約</a></li>
        <li><a href="/profile">プロフィール</a></li>
        <li><a href="/pet">ペット</a></li>
        {is_mobile ? <li onClick={logoutHandler}>ログアウト</li> : null}
      </ul>
    </div>
   )
}

SideMenu.propTypes = {
  selcted:PropTypes.string | null,
  is_mobile:PropTypes.bool
};

SideMenu.defaultProps = {
  selcted:null,
  is_mobile:false
};
