import React, { useEffect, useState  } from 'react';

import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import GoogleMap from './Map';
import { SitterCard } from '../sitter/SitterCard';
import Sitter from '../sitter/Sitter';
import useSitterState from '../sitter/useSitterState';
import { CloseBtn } from '../stories/closebtn/CloseBtn';

import "./map.css"
function MapApp(props:PropsValue) {
  const [marks,setMarks] = useState(null)
  const [selectedItem,setSelectedItem] = useState(null)
  const [selectd_sitter, SetSecltedSitter] = useState(null)
  const [position,setPosition] = useState(null)
  const {sitter_list, getSitterListByLocation} = useSitterState()
  useEffect(() => { 
    if(!window.navigator.geolocation){
      setPosition({lat:35.7125736, lng:139.7802043})
    }else{
      window.navigator.geolocation.getCurrentPosition(
      (_position) => {
      
        setPosition({lat:_position.coords.latitude, lng:_position.coords.longitude})
        //setPosition({lat:35.7125736, lng:139.7802043})
      },
      (error) =>{
        setPosition({lat:35.7125736, lng:139.7802043})
      }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => { 
    if(sitter_list){
      setMarks(sitter_list.map(sitter => {
        return {lat:sitter.profile.lat,lng:sitter.profile.lng, id:sitter.id}
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sitter_list]);

  useEffect(() => { 
    if(position){
      getSitterListByLocation(position.lat, position.lng, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[position]);


  useEffect(() => { 
    if(selectedItem){
      SetSecltedSitter(<div className="overlay-content-wrap" ><SitterCard sitter={new Sitter(selectedItem)}/></div>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedItem]);


  const closeOverLay  = () => {
    SetSecltedSitter(null)
  }
  const onClickHandler = (e) => {
 
    const selceted = sitter_list.find(sitter => sitter.id === e.id)
    setSelectedItem(selceted)
  }
  

  return(
    <div className="top">
      {selectd_sitter ? <div className="overlay" onTouchEnd={closeOverLay} onClick={closeOverLay}></div> : null}
      {selectd_sitter ? <CloseBtn onClick={closeOverLay} /> : null}
      <Header />
      <main >
      
       
        <div className="map">
          <div className="googlemap">
           { position ? <GoogleMap position={position} marks={marks} onClickHandler={onClickHandler}/> : null }
          </div>
         
        </div>
        <Footer selected={"home"} />
      </main>
      {selectd_sitter}
    </div>
  )
}

export default MapApp
