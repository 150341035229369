import { useState } from 'react';
import BookActions from './BookActions';
import {isSameDay,isSameMinute, isAfter, parseISO} from 'date-fns';
function useBookState() {

  const [item, setItem] = useState(null)
  const [list, setList] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)
  const [created_at, setCreatedAt] = useState(null)
  const [error, setError] = useState(null)

  const lisLoadedHandler = (data) => {
    setList(data.data)
    setUpdatedAt(new Date())
  }

  const itemLooadedHandler = (data) => {
    setItem(data.data)
    setUpdatedAt(new Date())
  }

  const createdHandler = (data) => {
    setCreatedAt(new Date())
  }


  const errorHandler = (data) => {
    setError(data)
  }

  const getList = (sitter_id, start_date, end_date,) => {
    BookActions.getList(sitter_id, start_date, end_date, lisLoadedHandler, errorHandler)
  }

  const getPastList = (token) => {
    BookActions.getPastList(token,lisLoadedHandler, errorHandler)
  }

  const getScheduledList = (token) => {
    BookActions.getScheduledList(token,lisLoadedHandler, errorHandler)
  }

  const getItem = (token,id) => {
    BookActions.getItem(token, id, itemLooadedHandler, errorHandler)
  }

  const create = (token, item)  => {
    BookActions.create(token, item, createdHandler, errorHandler)
  }


  const getRestByTargetDateFromList = (list, target_date) => {
    const _list = list.filter(item => isSameDay(target_date, parseISO(item.start_at))).filter(item => item.rest === true)
    if(_list.length > 0) return _list[0]
    return  null
  }
  const isAvailableByTargetDateFromList = (list, target_date) => {
    const result = list.filter(item => {
      const start_at = parseISO(item.start_at).getTime()
      const end_at = parseISO(item.end_at).getTime()
      const  target_at = target_date.getTime()
      if(target_at >= start_at && target_at < end_at) return true
      return false
    })
    return result.length === 0 ? true : false
  }

  const isSelected =  (book, target_date) => {
    if(!book) return false
    if((isSameMinute(target_date, book.start_at) || isAfter(target_date, book.start_at))
      ){
      return true
    }
    return false
  }

  return{ 
    book_item:item, book_list:list, book_error:error, updated_book_at:updated_at, created_book_at:created_at,
    getBookList:getList,createBook:create, getPastBookList:getPastList, getScheduledBookList:getScheduledList,getBookItem:getItem,
    getRestByTargetDateFromList, isAvailableByTargetDateFromList, isSelected
  };
}

export default useBookState