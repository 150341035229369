import { useState } from 'react';
import AuthAction from './AuthAction';
import cookie from 'react-cookies'

function useAuthState() {

  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)


  const completedHandler = (data) => {
    setToken(data)
  }

  const authHandler = (data) => {
    if(data.data){
      cookie.save('user_token', data.data.access_token, { path: '/' })
      cookie.save('refresh_token', data.data.refresh_token, { path: '/' })
      setToken(data.data.access_token)
    }
  }

  const errorHandler = (data) => {
    //window.location.href = "/login"
    setError(data)
  }
  const login = (username, password) => {
    AuthAction.login(username, password, authHandler, errorHandler);
  };  


  const getToken = () => {
    AuthAction.getToken( completedHandler, errorHandler)
  }
  
  const logout = () => {
    AuthAction.logout()
    window.location.href = "/login"
  }


  return { token:token, loginError:error, auth:getToken, getToken, login, logout:logout};
}

export default useAuthState