

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import "./sitter.css"
import Search from '../searchBox/Search';
import { SitterList } from './SitterList';


function SitterListApp(props:PropsValue) {

  const query = new URLSearchParams(useLocation().search);

  const name = (query.get("name"))
  const area = (query.get("area"))
  const date = (query.get("date"))
  const search = Search({name:name, area:area, date:date})
  useEffect(() => {
    
  },[]);


  return(
    <div className="top">
    <Header />
    <main  className="innner">
      <div className="sitter-list">
        <SitterList search={search} />
      </div>
    
    <Footer />
    </main>
  </div>
  )
}

export default SitterListApp

