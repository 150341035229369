import { Record } from 'immutable'

const PetProps = Record({
  id:null,
  name: null,
  type: null,
  type_detail:null,
  birthday:null,
  weight:null,
  gender:null,
  photo:[],
  comment:null,
  notice:null
});
export default PetProps;
