import React, { useEffect  } from 'react';
import { Logo } from '../stories/logo/Logo';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import SearchBox from '../searchBox/SearchBox';


import { SitterList } from '../sitter/SitterList';
function HomeApp(props:PropsValue) {

  
  useEffect(() => { 
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



  return(
    <div className="top">
      <Header />
      <main >
        <div className="home_logo">
          <Logo width={200} type="image" />
        </div>
        <SearchBox />
        <div className="sitter-list">
          <SitterList search={{}} />
        </div>
        <Footer selected={"home"} />
      </main>
    </div>
  )
}

export default HomeApp
