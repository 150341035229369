

import React, { useEffect , useState } from 'react';

import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import useAuthState from '../auth/useAuthState';
import useProfileState from '../profile/useProfileState';
import Profile from "./Profile.tsx"
import usePostCodeState from '../postcode/usePostCodeState';
import { SideMenu } from '../menu/mypage/SideMenu.jsx';
import "./profile.css"
import { PrefectureSelect } from '../prefectures/PrefectureSelect.jsx';

function ProfileApp(props:PropsValue) {
   const num_pattern = /^\d*$/
  const [item, setItem] = useState(null)
  const [message, setMessage] = useState(null)
  const {token, getToken} = useAuthState()
  const {address, updatePostCode} = usePostCodeState(null)
  const [post_code_message, setPostCodeMessage] = useState(null)
  const {profile, profile_created_at, profile_error, getProfile, createProfile} = useProfileState()
 

  useEffect(() => {
    if(profile){
     setItem(new Profile(profile))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);
  useEffect(() => {
    if(profile){
      if(!profile.pets || profile.pets.length === 0){
        window.location.href = "/pet"
      }else{
        setMessage(<div className="message">更新されました</div>)
      }
      
      

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile_created_at]);

  useEffect(() => {
    if(address && address.length > 0 && address[0].prefCode > 0){
      const _address =  address[0]
      /** props.changeHandler("address_1",Number(_address.prefCode))
      props.changeHandler("address_2",_address.city)
      props.changeHandler("address_3",_address.town) **/
      const new_item = item.set("prefecture", Number(_address.prefCode)).set("address_1",_address.city).set("address_2", _address.town)
      setItem(new_item)
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[address]);
  useEffect(() => {
    if(profile_error){
      setItem(new Profile())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile_error]);
  

  useEffect(() => {
    if(token){
      getProfile(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  const authHandler = (_t) =>{
    getToken()
  }

  const changeHandler = (e) => {
    const target = e.target
    

    if(target.name === "postcode"){
      //半角でなければSearchしない
      if(num_pattern.test(target.value)){
        setPostCodeMessage(null)
        updatePostCode(target.value)
      }else{
        setPostCodeMessage(<div className="notice">半角数字を入力してください</div>)
      }
    }
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  }

  const send = () => {
    createProfile(token, item)
  }


  const changePrefHandler = (e) => {
    const target = e.target
    const new_item = item.set("prefecture", target.value)
    setItem(new_item)

    //住所自動検索
  }

  return(
    <div className="top">
    <Header is_auth_required={true} authHandler={authHandler} />
    <main  className="innner">
      <div className="detail-wrap user-detail">     
        <div className="detail-left detail">
          <SideMenu selcted="profile" />
        </div>
        <div className="detail-right">
          <div className="main-white-box">
            <h1>プロフィール</h1>
            <div className="formbox profile">
             
              <div className="detail">
              <div className="card-item"><label>名前</label><div className="value"><input type="text" name="name" placeholder='大塚 いずこ' value={item ? item.name : undefined} onChange={changeHandler} /></div></div>
              </div>
              <div className="detail">
              <div className="card-item"><label>ふりがな</label><div className="value"><input type="text" name="name_f" placeholder='おおつか　いずこ' value={item ? item.name_f : undefined} onChange={changeHandler} /></div></div>
              </div>

              <div className="detail">
              <div className="card-item"><label>電話番号</label><div className="value"><input type="text" name="tel" placeholder='012055555555' value={item ? item.tel : undefined} onChange={changeHandler} /></div></div>
              </div>

              <div className="detail">
              <div className="card-item"><label>郵便番号</label><div className="value"><input type="text" name="postcode"  placeholder='1080073' value={item ? item.postcode : undefined}  onChange={changeHandler} />{post_code_message}</div></div>
              </div>

              <div className="detail">
              <div className="card-item"><label>都道府県</label><div className="value"><PrefectureSelect changeHandler={changePrefHandler} selected_id={item ? item.prefecture : undefined} /></div></div>
              </div>

              <div className="detail">
              <div className="card-item"><label>住所1</label><div className="value"><input type="text" name="address_1" placeholder='市区町村' value={item ? item.address_1 : undefined} onChange={changeHandler}  /></div></div>
              </div>

              <div className="detail">
              <div className="card-item"><label>住所2</label><div className="value"><input type="text" name="address_2" placeholder='番地' value={item ? item.address_2 : undefined} onChange={changeHandler}  /></div></div>
              </div>

              <div className="detail">
              <div className="card-item"><label>住所3</label><div className="value"><input type="text" name="address_3" placeholder='建物名など' value={item ? item.address_3 : undefined}  onChange={changeHandler} /></div></div>
              </div>
              {message}
              <div className="btn-box" ><button onClick={send} className="progress big">{item && item.id ? "更新" : "登録"}</button></div>

            </div>
          </div>
        </div>
    </div>
    
      <Footer />
    </main>
  </div>
  )
}

export default ProfileApp

