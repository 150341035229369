import React, { useState, useEffect } from 'react'

import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import useAuthState from '../auth/useAuthState';
import "./login.css"
import "../styles/centerBox.css"
import "../styles/form.css"
import useProfileState from '../profile/useProfileState';

function LoginApp(props:PropsValue) {


  const {token, loginError, login} = useAuthState()
  const [msessage, setMessage] = useState(null)
  const [username, setUserName] = useState(null)
  const [password, setPassword] = useState(null)

  const {profile, profile_error, getProfile} = useProfileState()
  
  useEffect(() => {
    
    if(token ){
      getProfile(token)
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {
    
    if(profile ){
      if(profile.pets.length === 0  ){
        window.location.href = "/pet"
      }else{
        window.location.href = "/home"
      }
      
    } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);


  useEffect(() => {
    
    if(profile_error ){
      window.location.href = "/profile"
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile_error]);



  useEffect(() => {
    
    if(loginError){
      setMessage("IDまたはパスワードが一致しません。")
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loginError]);

  const loginHandler = () => {
    setMessage(null)
    login(username, password)
  }


  return(

    <div className="top">
      <Header />
       <main  className="innner">
        <div className="center-box">
          
            <div className="form-item">
              <label>メールアドレス</label>
              <div className="input"><input  type="text" name="username" placeholder="" label="メールアドレス" value={username}  onChange={(e) => setUserName(e.target.value)} /></div>
            </div>
            <div className="form-item" >
            <label>パスワード</label>
            <div className="input"><input type="password" name="password" placeholder="" label="パスワード" value={password}     onChange={(e) => setPassword(e.target.value)} /></div>
            </div>
         
          <div className='message'>{msessage}</div>
          <div className="progress-btn" ><button onClick={loginHandler} className="progress big">ログイン</button></div>
          <div className="new-user" ><a href="/user/create">新規ユーザー登録</a></div>
        </div>
        <Footer />
    </main>
  </div>

  )
}

export default LoginApp
