import React,{  } from 'react';

import Book from './Book.tsx';
import { format } from 'date-fns'
import "./book.css"
export const BookCardForScheduled = ({
  book,
}) => {


  const clickHandler = () => {
    window.location.href = "/book/" + book.id
  }
  
  return(
    book ? 
    <div className="book-card" onClick={clickHandler} >
      <div className="card-item"><label>予約日時</label><div className="value">{format(book.start_at, "yyyy年M月d日 H時mm分")} 開始</div> <label>予約状況</label><div className="value">{book.status } 未確定</div> </div> 
      <div className="card-item"><label>シッター</label><div className="value">{book.sitter.profile.name} </div> </div> 
      </div> : null
   )
}

BookCardForScheduled.propTypes = {
  book:Book,
 
};

BookCardForScheduled.defaultProps = {
  book:{},
 
};
