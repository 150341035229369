import config from 'react-global-configuration';
import Action from '../action/Action';
var MediaActions = {

  create(token, file, category, callback, errorHandler ){

    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', category)
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_MEDIA"), token, "POST",  {},  formData,  callback, errorHandler)
  },


  createPetPhoto(token, file, pet_id, callback, errorHandler ){

    const formData = new FormData();
    formData.append('file', file);

    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_PET") + "/" + pet_id + "/medias", token, "POST",  {},  formData,  callback, errorHandler)

  
  },

  /**update(token, id, media){
      fetch(config.get("API_PATH") + config.get("API_MEDIA") + "/" + id , {
        method: "DELETE",
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(function(response) {

        if(response.status === 204  ){
            return true
        }else if(response.status === 401){
          window.parent.location.href = config.get("LOGIN_URL")
        }else if(response.status === 413){
          return  {"error":413}
        }throw new Error('Network response was not ok.');
      }).then(function(data) {
        if(data && !data.error){

        }else{
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            code:data.error
          })
        }
      }).catch(function(error) {
          AppDispatcher.dispatch({
            actionType: Constants.API_ERROR,
            message:config.get("error_api_down")
          })
        });
    } **/


}

export default MediaActions
