import React, {   useEffect } from 'react'
import {useParams} from 'react-router-dom';

import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import { SideMenu } from '../menu/mypage/SideMenu.jsx';
import useAuthState from '../auth/useAuthState';
import useBookState from './useBookState';
import { BookDetailItem } from './BookDetailItem.jsx';
import { BookSitterItem } from './BookSitterItem.jsx';
import "./book.css"



function BookApp(props:PropsValue) {

  const {book_id} = useParams();
  const {token, getToken} = useAuthState()

  const {book_item,getBookItem} = useBookState()

  
  useEffect(() => {
    getBookItem(token,book_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  const authHandler = (_t) =>{
    getToken()
  }


  return(
    <div  className="top">
      <Header is_auth_required={true} authHandler={authHandler}/>
      <main  className="innner">
      <div className="detail-wrap book-detail">
        <div className="detail-left detail">
          <SideMenu selcted="books" />
        </div>
        <div className="detail-right">
          <div className="book-detail-info">
            <h2>予約詳細</h2>
             {token ? <BookDetailItem token={token} book={book_item} /> : null} 
          </div>

          <div className="book-detail-info">
            <h2>シッター詳細</h2>
             {token ? <BookSitterItem token={token} book={book_item} /> : null} 
          </div>


        </div>

    
      </div>
      <Footer />
      </main>
    </div>    
  )
}

export default BookApp

