import Action from "../action/Action";
import config from 'react-global-configuration';
import {format} from "date-fns"
//import MessageActions from "./MessageActions";

var BookActions = {



  getList( sitter_id, start_date, end_date, callback, errorHandler){
    const url = config.get("API_PATH")  + config.get("API_BOOK")  + "?sitter_id=" +sitter_id + "&date_start=" + format(start_date, "yyyy-MM-dd") + "&date_end=" + format(end_date, "yyyy-MM-dd")
    Action.fetchJson( url , "GET", {}, null,  callback, errorHandler)
  },

  
  getItem(token, id, callback, errorHandler){

    const url = config.get("API_PATH")  + config.get("API_BOOK")  + "/" +id 
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },



  getPastList(token,  callback, errorHandler){
    const url = config.get("API_PATH")  + config.get("API_BOOK")  + "/mine?type=past&offset=0"
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },

  getScheduledList(token,  callback, errorHandler){
    const url = config.get("API_PATH")  + config.get("API_BOOK")  + "/mine?type=scheduled&offset=0"
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },


  create( token, book ,callback, errorHandler ) {
    const  request_data = book.createFormData()
    const method = book.id  ? "PATCH" : "POST"
    const url = config.get("API_PATH")  + config.get("API_BOOK") 
    Action.fetchAuthorizedJson( url , token, method, {'Content-Type': 'application/json'}, JSON.stringify(request_data),  callback, errorHandler)
  },




}

export default BookActions
