import React, { useState } from 'react'
import config from 'react-global-configuration';
import ValueItem from '../form/ValueItem'
import { SearchBtn } from '../stories/searchBtn/SearchBtn';
import format from 'date-fns/format';
import Search from './Search'
import { PrefectureSelect } from '../prefectures/PrefectureSelect.jsx';
import "./search.css"
function SearchBox(props:PropsValue) {


   /**const select_customStyles = {
    valueContainer : (provided, state) => ({
      ...provided,
      padding:"2px 0 ",
      display:"block",
      fontSize:"14px"
    }),
    menu: (provided, state) => ({
      backgroundColor:"#fff",
      padding: 1,
      border:"2px solid #d4cbb0",
      marginTop:"-32px"
    }),
    control: () => ({
      border: "0",

      display:"flex",
    }),
    
    indicatorSeparator:() => ({
      width:"0"
    }),
    indicatorsContainer: () => ({
      padding:"0",
      flexDirecrtion:"row", 
      display:"none"
    })
  }**/

  const [item, setItem] = useState(new Search())

  

  function changeHandler(name,value){
    setItem(item => item.set(name,value))
  }

  function search(){

    let params = new URLSearchParams();
    if(item.name)
       params.set("name",item.name )
    if(item.area)
      params.set("area",item.area)
    if(item.date)
      params.set("date",format(item.date,"yyyy-MM-dd"))

    const query_string =  params.toString()
    window.location.href = "/sitter?" + query_string 

  }

  return(
    <div  className={"searchBoxWrap"}>
      <div className={"searchBox"}>
        <div className="inputBox inputBoxWithBorder w100" ><ValueItem name="name" placeholder="シッター名を入力。" label="名前で探す" default={item.name} className="search-box-item"  changeHandler={changeHandler} /></div>
        <div className="inputBox inputBoxWithBorder w100">
        <div className="search-box-item">
          <label>場所で探す</label>
          <PrefectureSelect changeHandler={changeHandler} selected_id={item ? item.area : undefined} no_border={true}/>
          </div>
        </div>
        <div className="inputBox w100"><ValueItem name="date" type="date" placeholder="日付をお選びください。" label="日付から探す" default={item.date} className="search-box-item"  changeHandler={changeHandler} options={config.get("PREFS")} /></div>
        <SearchBtn onClick={search} />
      </div>
    </div>
  )
}

export default SearchBox

