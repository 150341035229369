import { useState } from 'react';
import PetActions from './PetActions';


function usePetState() {

  const [item, setItem] = useState(null)  

  const [created_at, setCreatedAt] = useState(null)
  const [error, setError] = useState(null)



  const createdHandler = (data) => {
    setCreatedAt(new Date())
    setItem(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const get = (token, pet_id)  => {

    PetActions.getItem(token, pet_id, createdHandler, errorHandler)
  }

  const create = (token, item)  => {
    PetActions.create(token, item, createdHandler, errorHandler)
  }



  return{ 
    loaded_pet:item,pet_item:item,  pet_error:error,  created_pet_at:created_at,
    createPet:create, getPet:get
  };
}

export default usePetState