import React,{ useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { setMinutes, setHours, addMinutes, format } from 'date-fns';
import useBookState from '../book/useBookState';
import Sitter from './Sitter';
import ja from "date-fns/locale/ja";
import "./sitter.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';


export const TimeSheet = ({
  sitter,
  target_date
}) => {

  const [times, setTimes] = useState(null)
  
  const {book_list, updated_book_at, getBookList,isAvailableByTargetDateFromList} = useBookState()

 

  useEffect(() => { 
    getBookList(sitter.account_id, target_date, target_date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  useEffect(() => {
    
    if(sitter && book_list){
      
      const start_time = sitter.getOpenStartTime(target_date)
      const end_time = sitter.getOpenEndTime(target_date)

      const td = setMinutes(setHours(target_date,Math.ceil(start_time / 60 )), 0)
      const dif_hour = Math.ceil((end_time - start_time) / 60 )
      const time_array = (new Array(dif_hour)).fill(null).map( (t, index) => {
      const target_date_time = addMinutes(td, index * 60)

      const is_abilable = isAvailableByTargetDateFromList(book_list, target_date_time)
    

        return (
          <div onClick={() => setBookTime(target_date_time )} className="time-item">
            <div className="time">{format(target_date_time, "H:mm〜",{ locale: ja })}</div>
            <div className="to-book">{is_abilable ? <div> 予約へ進む <span className="red"><FontAwesomeIcon icon={faCircleArrowRight} /> </span></div> : "-"}</div>
          </div>
        )
      })

      setTimes(time_array)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitter, updated_book_at])

  const setBookTime = (e) => {
    window.location.href = "/book/create/" + sitter.account_id + "/" + format(e, "yyyy-MM-dd") + "/" + format(e, "H") 
  }

  return(
   <div className="time-sheet">
     {times}
   </div>
   )
}

TimeSheet.propTypes = {
  sitter:Sitter,
  target_date:PropTypes.instanceOf(Date)
};

TimeSheet.defaultProps = {
  sitter:{},
  target_date: new Date()
};
