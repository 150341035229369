

import React, { useEffect  } from 'react';

import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import { SideMenu } from './mypage/SideMenu';
import useAuthState from '../auth/useAuthState';
import useProfileState from '../profile/useProfileState';
import "./menu.css"


function MenuApp(props:PropsValue) {

  const {token, getToken} = useAuthState()
  const {profile,  profile_error, getProfile} = useProfileState()
 



  useEffect(() => {
    if(profile_error){
      window.location.href = "/profile"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile_error]);
  

  useEffect(() => {
    if(token){
      getProfile(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  const authHandler = (_t) =>{
    getToken()
  }



  return(
    <div className="top">
    <Header is_auth_required={true} authHandler={authHandler} />
    <main  className="innner">
      <div className="detail-wrap user-detail">     
      
        <div className="detail-right">
          <div className="main-white-box">
            <h1>メニュー</h1>
            {profile ? <SideMenu is_mobile={true} /> : null}
            <div className="link">
              <div><a href="/company">会社概要</a></div>
              <div><a href="/privacy">プライバシーポリシー</a></div>
              <div><a href="/ec">特定商取引法に基づく表記</a></div>
              <div><a href="/regulation">サービス利用規約</a></div>
            </div>
            
          </div>
        </div>
    </div>
    
      <Footer />
    </main>
  </div>
  )
}

export default MenuApp

