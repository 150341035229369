import React,{ useEffect,useState } from 'react';
//import PropTypes from 'prop-types';
import Sitter from './Sitter';
import Search from '../searchBox/Search';
import "./sitter.css"
import useSitterState from './useSitterState';
import { SitterCard } from '../sitter/SitterCard';

export const SitterList = ({
  search,
}) => {

  const [sitter_list_items, setSitterList] = useState(null)
  const {sitter_list, siter_updated_at, getSitterList} = useSitterState()
  useEffect(() => { 

    getSitterList(search.date, search.area, search.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => { 
    if(sitter_list){
      setSitterList(sitter_list.map(item => {
        const  sitter = (new Sitter(item))
        return <SitterCard sitter={sitter} key={"sitter_" + item.id}/>
      }))
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[siter_updated_at]);



  return(
    <div className="sitter-list">
      {sitter_list_items}
    </div>
   )
}

SitterList.propTypes = {
  search:Search,
};

SitterList.defaultProps = {
  search:{}
};
