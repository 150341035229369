import { Record } from 'immutable'

const ProfileProps = Record({
  id:null,
  name: null,
  name_f: null,
  tel:null,
  postcode:null,
  prefecture:null,
  address_1:null,
  address_2:null,
  address_3:null,
  photo:null,
  pets:[]
});
export default ProfileProps;
