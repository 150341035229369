import React, {  } from 'react';
import { Logo } from '../stories/logo/Logo';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import "./page.css"
function NoticeApp(props:PropsValue) {

   



  return(
    <div className="top">
      <Header />
      <main className="full">
        <div className="home_logo static">
          <Logo width={100} type="image" />
        </div>
        <div className="white">
          <div className="content">
            <h1>注意事項</h1>
            <div  className="about">
            <p>作成中</p>
           
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default NoticeApp
