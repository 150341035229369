import React,{ useEffect, useState  } from 'react';
import { format } from 'date-fns';
import { PetIcon } from '../pet/PetIcon';
import Book from './Book.tsx';


export const BookDetailItem = ({
  token,
  book
}) => {

  const [item, setItem] = useState(null)
  const [pets, setPets] = useState(null)





  useEffect(() => {
   
    if(book){
      setItem(book)
      const _pets = book.pets.map(pet => {
        return <div className="petitem"><PetIcon pet={pet} /><div className="name">{pet.name}</div></div>
      })
      setPets(_pets)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[book]);




  return(
    item ? 
      <div className="book-items">
        <div className="book-item"><label>予約名</label><div className="value">{item ? item.name : null }</div></div>
        <div className="book-item"><label>予約日時</label><div className="value">{format(item.start_at, "yyyy年M月d日 HH:mm") }　〜　{format(item.end_at, "yyyy年M月d日 HH:mm")  }</div></div>
       
        <div className="book-item"><label>ペット</label><div className="value petitems">{pets}</div></div>
        <div className="book-item"><label>シッター名</label><div className="value petitems">{item && item.sitter  ? item.sitter.profile.name : null }</div></div>
        <div className="book-item"><label>メモ</label><div className="value">{item ? item.memo : null } </div></div>
    
        
      </div>
    : null
   )
}

BookDetailItem.propTypes = {
  token:String,
  book:Book,

};

BookDetailItem.defaultProps = {
  token:"",
  book:null,
};

