
import 'whatwg-fetch'
import Action from "../action/Action"
import config from 'react-global-configuration';
import cookie from 'react-cookies'

const AuthAction =  {

  login(loginname,password,callback, errorHandler){

    const data = new FormData() 
    data.append('grant_type', "password")
    data.append('auth_type', "password")
    data.append('username', loginname)
    data.append('password', password)
    data.append('client_id',1)
    data.append('client_secret', "JZg9yjCffOdr1akWXcRUVt5ijinl1zt5Yi86Bc23")
    data.append('scope', "user")
    
    let headers = new Headers();
    Action.fetchJson( config.get("API_PATH") + "/oauth/auth"  , "POST", headers, data,  callback, errorHandler)
  },

  getToken(  callback, errorHandler){
    var item =  cookie.load("user_token")
    if(item)
      callback(item)
    else
    errorHandler({error:1})
  },


  logout(){
    cookie.remove("user_token", { path: '/' })
  },

   
}

export default AuthAction
