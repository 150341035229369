import React, {  } from 'react';
import { Logo } from '../stories/logo/Logo';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import "./page.css"
function UsageApp(props:PropsValue) {

   



  return(
    <div className="top">
      <Header />
      <main className="full">
        <div className="home_logo static">
          <Logo width={100} type="image" />
        </div>
        <div className="white">
          <div className="content">
            <h1>ご利用方法</h1>
            <div  className="about">
            <p>Petcareは、自分に合うペットシッターを探し、アプリやwebサイトから依頼することができます。</p>
            <p>安心安全にご利用いただくために、ご家族揃っての事前面談の実施をお願いしております。ペットシッターと飼い主様で直接メッセージや予約のやりとりを行っていただき、コミュニケーションが取れるようにしています。</p>

            <p>ペットシッター、飼い主ともにアプリやWEBページから会員登録制となります。<br/>
            ペットシッターの登録には審査があり、有資格者限定であり、飼い主の登録にもカードの登録が必須です。<br/>
            シッターの検索にはいくつかの方法があります。地図の表示エリア内でのシッター指定機能。地域など任意の検索条件によって選ばれたシッターの登録情報から。以前依頼したシッターのリピートとなります。</p>

            <p>シッター選んだら予約のリクエストを行い、条件をすり合わせてシッターから見積もりを発行していただきます。見積もりを承認したら予約の確定となります。</p>

            <p>当日ペットをシッターにお任せします。シッティング時には写真を含めた詳細な報告がメールで届きますので、外出先でも確認することができます。</p>

            <p>サポート完了後、シッターより完了報告が届きますので、報告内容に問題が無ければ、完了報告を承認し、レビューを記入し、お支払いをします。クレジットカード決済がご利用いただけます。</p>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default UsageApp
