import { useState } from 'react';
import MediaActions from './MediaActions';

function useMediaState() {

  const [item, setItem] = useState(null)  
  const [error, setError] = useState(null)
  const [item_updated_at, setItemUpdatedAt] = useState(null)

  const itemLooadedHandler = (data) => {
    setItem(data)
    setItemUpdatedAt(new Date())
  }

  const errorHandler = (data) => {
    setError(data.error)
  }

  const update = (token, file, category)  => {
    MediaActions.create(token, file, category, itemLooadedHandler, errorHandler)
  }

  const updatePetPhoto = (token, file, pet_id)  => {
    MediaActions.createPetPhoto(token, file, pet_id, itemLooadedHandler, errorHandler)
  }

  return{ 
    media:item, media_error:error, media_updated_at:item_updated_at,
    createMedia:update, updatePetPhoto
  };
}

export default useMediaState