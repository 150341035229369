import React from 'react';
import PropTypes from 'prop-types';
import './add_lef_btn.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

/**
 * Primary UI component for user interaction
 */
export const AddLeftBtn = ({ type, label, onClick, ...props }) => {
  const className = type === "default" ? "add-left-btn" : "add-left-btn ";
  return (
    <div
      className={className}
      {...props}
    >
      <div className="add-left-btn" onClick={onClick ? onClick : () => {}}>
        <FontAwesomeIcon icon={faCirclePlus} />&nbsp;{label}
      </div>
    </div>
  );
};

AddLeftBtn.propTypes = {

  type: PropTypes.oneOf(['default', 'white']),
  label :PropTypes.string | null, 
  /**
   * Optional click handler
   */
  onClick: PropTypes.func |  undefined,
};

AddLeftBtn.defaultProps = {
  type: "default",
  label: null,
  onClick: undefined,
};
