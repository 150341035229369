import React, {  useEffect, useState } from 'react'
import useAuthState from '../auth/useAuthState';
import { Logo } from '../stories/logo/Logo';

import "./header.css"
import LoginBox from '../login/LoginBox';
function Header(props:PropsValue) {

  const {token, auth, logout}  = useAuthState()
  const [isToken, setIsToken]  = useState(false)
  const [loginBox, setLoginBox] = useState(null)

  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {

    if(token){
      setLoginBox(null)
      setIsToken(true)
      if(props.authHandler){
        props.authHandler(token)
      }
    }else{
     
      if(props.is_auth_required){
        setLoginBox(<LoginBox authHandler={authHandler} closeHander={closeLoginBox} />)
      }
      setIsToken(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  const authHandler = (_token) => {
    setIsToken(true)
    setLoginBox(null)
    if(props.authHandler){
      props.authHandler(token)
    }
  }


  function moveTo(path){
    closeMenu()
    window.location.href = path
  }

  function login(){
    moveTo("/login")
  }

  function logoutHandler(){
    logout()
    moveTo("/login")
  }


  const closeLoginBox = () =>{
    moveTo("/login")
  }


  function closeMenu(){
   
    const element = document.querySelector("menu");
    if(!element) return false
    
    element.style.display = "none"
  }

  return(

      <div className={"header-wrap"}>
        <header className={"header"} >
          <div className="logo">
              <Logo onClick={() => {window.location.href="/home"}} />
            </div>
          <ul className="header-menu">
            <li  onClick={() => moveTo("/home")} >トップ</li>
            <li  onClick={() => moveTo("/map")} >シッター検索</li>
            <li  onClick={() => moveTo("/about")} >Petcareとは</li>
            <li  onClick={() => moveTo("/use")}>ご利用方法</li>
            <li  onClick={() => moveTo("/notice")}>注意事項</li>
            <li  onClick={() => moveTo("/faq")}>よくある質問</li>
          </ul>
          <div className="right-menu">
            { isToken ? null :<div onClick={() => login()}>ログイン</div> }
            { isToken ? null :<div  className="button" onClick={() => moveTo("/user/create")}>新規登録</div> }
            { isToken ?  <div  onClick={() => moveTo("/books")}>マイページ</div>  : null}
            { isToken ?  <div  onClick={logoutHandler}>ログアウト</div>  : null}
           
          </div>
        </header>
        {loginBox}
    </div>

  )
}

export default Header


/**
 * <div id="header_triger" className="hamburger-menu" onClick={displayMenu}>
          <input type="checkbox" id="menu-btn-check" />
          <label for="menu-btn-check" className="menu-btn"><span></span></label>
      </div>


      <menu>
      <div className="close" onClick={closeMenu}><img src={close_btn} alt="close" /></div>
      <div id="menu"  >    
        <ul id="menu-innter">
          <li  onClick={() => moveTo("/")} >トップ</li>
          <li  onClick={() => moveTo("/")} >Petcareとは</li>
          <li  onClick={() => moveTo("/")}>ご利用方法</li>
          <li  onClick={() => moveTo("/")}>注意事項</li>
          <li  onClick={() => moveTo("/")}>よくある質問</li>

        
          { isToken ? null :<li onClick={() => login()}>ログイン</li> }
          { isToken ? null :<li className="mob-new" onClick={() => moveTo("/user/create")}>新規登録</li> }
          { isToken ?  <li   onClick={logoutHandler}>ログアウト</li>  : null}
          { isToken ?  <li  onClick={() => moveTo("/mypage/book/comming")}>マイページ</li>  : null}
        


        </ul>
      </div>
    </menu> 

 */