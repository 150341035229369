import React, {} from 'react';
import { Logo } from '../stories/logo/Logo';
import Header from "../header/Header"
import { Footer } from '../footer/Footer';
import "./page.css"

function RegurationApp(props:PropsValue) {

  

  return(
    <div className="top">
      <Header />
      <main className="full">
        <div className="home_logo static">
          <Logo width={100} type="image" />
        </div>
        <div className="white">
          <div className="content">
            <h1>サービス利用規約</h1>
            <p>本利用規約は、株式会社YOKUSURU（以下「当社」といいます）と利用者との間で、本サイト上で当社が提供する本サービスの利用に関する条件を定めることを目的とします（以下、「本規約」といいます）。利用者は、本サイトが、ペット・シッター等と飼い主等に対するサポートサービスの提供を受ける機会及び提供を行う機会の提供を目的とするものであり、当社は、いかなる場合においても、サポートサービス契約の当事者とはならないことを確認し、了承します。</p>
            <p>本サービスの利用希望者は、本規約の全てに同意いただき、当社が定める方法により会員登録をする必要があります。また、本サービスの利用により、会員は、本規約および会員が利用するサービスごとに定められた各種規約、ガイドラインや注意事項を遵守することに同意したものとみなされます。 </p>
            <p>本規約には、本サービスの提供条件及び当社と登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。 </p>
            <h2>第1条 適用</h2>
            <ol>
            <li>
            本規約は、当社とユーザーとの間の一切の権利義務関係を目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用するものとします。利用者は、本サイトが、飼い主とペットシッターに対するシッティングの提供を受ける機会及び提供を行う機会の提供を目的とするものであり、当社は、いかなる場合においても、シッティングに関する契約の当事者とはならないことを確認し、了承します。</li>
            <li>利用者は、本サイトへの登録を行い、本サイトの利用を開始することにより、本規約、および各種規約（以下「個別規定」といいます。）を遵守することに同意いただき、本サービスを利用するものとします。</li>
            <li>本規約以外の本サービスに関する個別規定、ガイドページ、よくあるお問い合わせも本規約の一部を構成するものとし、利用者はこれを遵守することに同意し、本サービスを利用するものとします。なお、本規約の内容と、各種規約やその他の本規約外における本サービスの説明などとが異なる場合は、個別規定の定めが優先されるものとします。</li>
            </ol>
<h2>第2条 定義</h2>
<ol>
<p>本規約および個別規定において使用する以下の用語は各々以下に定める意味を有するものとします。</p>
<li>
「登録メンバー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人および法人を意味します。
</li><li>
「登録情報」とは、第3条において定義された「登録情報」を意味します。
</li><li>
「ユーザー」とは、登録メンバーであるかどうかを問わず本サービスの提供を受け、又は受けようとする個人および法人で、当サイトの閲覧者も含みます。
</li><li>
「飼い主」とは、飼い主として当社の指定する手続きに基づき、本規約および飼い主規約に承諾し、当社所定の方法にて登録した登録メンバーを意味します。
</li><li>
「ペットシッター」とは、ペットシッターとして当社の指定する手続きに基づき、本規約およびペットシッター規約に承諾し、当社所定の審査を通過した登録メンバーを意味します。
</li><li>
「本サイト」とは、当社がインターネット上で提供する「Pet Care（ペットケア）」をいいます。なお、「本サイト」には、文脈上許される限り、後に定義する「本サービス」も含まれるものとします。
</li><li>
「本サービス」とは、当サイトにおいて、または当サイトを使用して当社が登録メンバーを含むユーザーに対し提供する情報提供サービスの総称のことをいいます。
</li><li>
「シッティング」とは、動物を世話することをいいます。
</li><li>
「事前面談」とは、サービス提供開始前に予めペットシッターが当社の指定する方法により動物の状態を対面で確認することをいいます。
</li><li>
「ホームステイプラン」とは、ペットシッターの自宅で24時を跨いでサービス提供することをいいます。
</li><li>
「ショートステイプラン」とは、ペットシッターの自宅で当社所定の時間でサービス提供することをいいます。
</li><li>
「ビジットプラン」とは、ペットシッターが飼い主の家に訪問し、当社所定の時間で散歩・食事などの世話をすることをいいます。
</li><li>
「ウォーキングプラン」とは、ペットシッターが飼い主の家に訪問し、当社所定の時間で散歩をすることをいいます。
</li>
</ol>
  <h2>3条 登録</h2>
  <ol>
    <li>
会員登録手続を行うことができるのは、その登録メンバーとなる本人に限るものとし、登録メンバーとなる本人以外による登録は一切認められません。
</li><li>
会員登録手続を行う者は、登録時に入力した情報（これを変更または修正したものを含み、以下「登録情報」といいます。）は登録内容の正確性・真実性・最新性などを保証するものとし、当社は登録内容の正確性・真実性・最新性を調査する義務は負いません。
</li><li>
登録内容の変更がある場合は、登録メンバーは直ちに登録内容を変更し、常に登録メンバー自らの正確な情報が登録されているよう、管理・変更する責任を負います。
</li><li>
登録メンバーとなるための条件は以下のとおりとします。<br/>
a.満18歳以上であること <br/>
b.日本語を理解し、読み書きできること<br/>
c.電子メールアドレスおよび電話番号を保有していること<br/>
d.既に登録メンバーとなっていないこと<br/>
e.自らが反社会的勢力でなく、過去にも反社会的勢力でなく、反社会的勢力を利用したことがないこと<br/>
f.本人確認書類の写しを提出すること<br/>
g.本規約および個別規定に同意すること<br/>
h.満18歳以上であって未成年である場合は、メンバーとなることおよび本規約に従って当サイトを利用することについて、会員登録する前に親権者の包括的な同意を得ていること
</li><li>
第4項乃至第5項にかかわらず、当社は、利用者が以下の各号のいずれかに該当し、または該当するおそれがあると判断した場合には、その登録を拒否することができ、また、登録後であってもその登録を、事前通知を行うことなく任意に削除することができます。当社はかかる対応について一切の説明義務を負わず、責任を負わないものとします。
a.当社の定める登録要件を満たさない場合または満たさなくなった場合<br/>
b.当社に提供された登録事項または提出書類の全部または一部につき虚偽、誤記または記載漏れがあった場合<br/>
c.未成年者の登録者につき、法定代理人の承諾を得ていなかった場合<br/>
d.反社会的勢力に該当する、または資金提供その他により反社会的勢力などの維持、運営に協力もしくは関与していると当社が判断した場合<br/>
e.過去に有罪判決（ただし、軽微な交通犯罪を除く。）を受けている場合<br/>
f.過去に当社との契約に違反した場合または登録を拒否もしくは取消しされた者である場合<br/>
g.禁止行為を行い、または行うおそれがあると当社が判断した場合<br/>
h.登録された連絡先（メールアドレス、電話番号等）への問い合わせに対し2週間以上返事がなかった場合<br/>
i.本サイト内において、同一利用者が複数の登録を行っていた場合<br/>
j.その他当社が登録を不適当と認めた場合<br/>
当社は、登録が完了した利用者に対してIDなどを発行します。当社は、利用者に発行されたIDなどを使用して行われた行為について、当該利用者の行為とみなすことができるものとし、利用者は当該行為について責任を負うものとします。利用者は、第三者にIDなどが使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。<br/>
利用者は、登録の内容に変更が生じたときは、速やかに、当社の定める方法により登録事項の変更を行うものとします。
</li>
</ol>
<h2>第4条 本サービスの内容</h2>
<ol>
<li>
本サービスは飼い主とペットシッターを結ぶオンラインプラットフォームを提供しています。当社は、いかなる場合においても、シッティングに関する契約の当事者とはなりません。
</li><li>
ペットシッターが実施するシッティングの内容は、次に掲げるものとします。
</li><li>
ペットシッターの自宅にまたは飼い主宅において、一時的に飼い主の犬をお世話すること。
</li><li>
本サイト上に記載するシッティングの内容として記載する事項
</li><li>
a.およびb.に付随して必要と認められる内容
</li><li>
シッティングに関する契約は、無償寄託契約として飼い主とペットシッターとが締結するものであり、無償寄託契約に関連して生じた紛争などについて、飼い主およびペットシッター間で解決することを原則とします。
</li><li>
飼い主は、シッターとの無償寄託契約締結後に当社に対して当社の定めるサイト利用料を支払うものとします。
</li><li>
ペットシッターは、当社との契約に内容に従い、当サイトを利用して写真などの情報（以下、「投稿情報」といいます。）を別途当社が定めるガイドラインに定める方法により当社に送付するものとします。また登録ユーザーは、当社に対し、投稿情報について、無償で利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含む）する権利を許諾するものとします。この利用の許諾は、永続的かつ取消不能のものとします。
</li>
</ol>
<h2>第5条 本契約</h2>
<ol>
<li>
ユーザー間の契約で発生する各種作業・連絡・法的義務の履行・トラブル対処などについては、登録メンバー同士で行うものとします。当社は、本規約または個別規定において特に定めたものを除き、本契約に関する事項について、一切責任を負わないものとします。
</li><li>
飼い主およびペットシッターは、成立した本契約の内容を履行する義務を負います。
</li><li>
飼い主がサービス利用を希望する場合は，まずホスト候補者と事前面談を行います。そのうえで飼い主が予約リクエストをし、ホストがそのリクエストを承認、飼い主が支払いを完了した時点で無償寄託契約が成立するものとします。
</li><li>
当社は本サイト内で締結された日時、期間など当社の定める事項の契約のみを監督し、本サイト外で合意された事項に関して当社は関知せず、その結果生じた損害について一切の責任を負わないものとします。
</li><li>
飼い主およびペットシッターは事前面談を経ていた場合においても、双方が無償寄託契約の締結を断る権利を有するものとし、双方合意のうえで無償寄託契約を締結するものとします。
</li><li>
無料寄託契約成立後、飼い主が当該契約を一方的に解約することによりキャンセルする場合、当社は、別途定めるキャンセルポリシーに従い、本サイト利用料から当社規定のキャンセル料および決済手数料を控除した金額を飼い主に対し返金するものとします。
</li><li>
前項にかかわらず、本契約の条件、本規約などに違反する行為がなされたことを、飼い主が契約の終了予定時刻から24時間以内（以下「異議申出期間」といいます。）に当社に異議を申し立て（以下「異議申立て」といいます。）、かつ、当社の定める一定の期間内にこれを立証した場合には、キャンセル期限経過後であっても、本契約をキャンセルすることができるものとします。この場合、当社は当社所定の条件により、飼い主にサービス利用料を返金するものとします。
</li><li>
前項の規定により本契約がキャンセルされた場合、ペットシッターはマーケティング協力費用を受領することができないものとします。
</li></ol>
<h2>第6条 費用の支払い方法</h2>
<ol>
<li>
サイト利用料の支払いについては、クレジットカード決済のみが利用可能です。クレジットカード決済は、当社が指定する決済サービス以外の決済方法による決済は認められません。
</li><li>
当社に対する飼い主の決済手数料については、原則当社が負担するものとします。ただし、飼い主の自己都合によりキャンセルまたは日程変更をする場合、キャンセルポリシーにしたがってキャンセル料および決済手数料が発生いたします。
</li></ol>
<h2>第7条 ペットシッター</h2>
<ol>
<li>
当社の審査を経て登録されたユーザーのみがペットシッターの資格を有します。
</li><li>
ペットシッターはホスト規約に別途定める禁止行為を行わないものとします。ペットシッターが以下の行為を行ったものと当社が判断した場合、当社はその事実を本サービス上で公表することができるものとし、ペットシッターはこれに一切の異議を申し立てないものとします。当社は、公表によってペットシッターに発生した損害について一切の責任を負わないものとします。
</li><li>
シッティング中の活動報告や写真の提供などサイト情報の更新などに対し，当社所定のマーケティング協力費をお支払いすることがあります。
</li></ol>
<h2>第8条 ユーザー</h2>
<ol>
    <li>
ユーザーは、ユーザーによる当サイトの利用とその当サイトを利用してなされた一切の行為（ユーザーによる利用または行為とみなされる他者の利用や行為を含みます。以下同様とします。）とその結果について一切の責任を負います。ユーザーは、当サイトの利用により当サイトまたは他者に対して損害を与えた場合（ユーザーが、本規約および個別規定の義務を履行しないことにより他者または当サイトが損害を被った場合を含みます。）、自己の責任と費用をもって損害を賠償するものとします。
</li><li>
登録メンバーは、登録したIDおよびパスワードについて、自己の責任の下で適切に管理し、IDおよびパスワードの盗用を防止する措置を自ら講じるものとします。登録メンバーは、ユーザーIDおよびパスワードの紛失、盗用並びに第三者による使用の事実またはそのおそれがある事実を発見した場合、直ちにその旨を当社に通知し、当社からの指示に従うものとします。
</li></ol>
<h2>第9条 禁止事項</h2>
当社は、以下に該当する行為を禁止します。ユーザーが以下に該当する行為を行った場合、当社は、その故意・過失であるかにかかわらず違反行為とみなすことができ、ユーザーについて以下の各号に該当する事実が判明した場合には、登録を取り消し、または当サイトもしくは本サービスの利用を禁止することがあります。
<ol>
    <li>
法令に違反する行為または犯罪行為に関連する行為
</li><li>
当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
</li><li>
公序良俗に反する行為
</li><li>
当社、本サービスの他の利用者またはその他の第三者の知的財産権、商標権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
</li><li>
特定個人の氏名・住所・電話番号・メールアドレスなど第三者が見て個人を特定できる情報を第三者に提供する行為
</li><li>
本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
a.
過度に暴力的または残虐な表現を含む情報<br/>
b.
コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報<br/>
c.
当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報<br/>
d.
過度にわいせつな表現を含む情報<br/>
e.
差別を助長する表現を含む情報<br/>
f.
自殺、自傷行為を助長する表現を含む情報<br/>
g.
薬物の不適切な利用を助長する表現を含む情報<br/>
h.
反社会的な表現を含む情報<br/>
i.
チェーンメールなどの第三者への情報の拡散を求める情報<br/>
j.
他人に不快感を与える表現を含む情報
</li><li>
本サービスのネットワークまたはシステムなどに過度な負荷をかける行為
</li><li>
本サイトに対するリバースエンジニアリングその他の解析行為
</li><li>
本サービスの運営を妨害するおそれのある行為
</li><li>
当社のネットワークまたはシステムなどへの不正アクセス
</li><li>
本サービス用設備に無権限でアクセスし、またはポートスキャン、DOS攻撃若しくは大量のメール送信などにより、その利用もしくは運営に支障を与える行為、または支障を与えるおそれのある行為
</li><li>
サーバなどのアクセス制御機能を解除または回避するための情報、機器、ソフトウェアなどを流通させる行為
</li><li>
本サービスまたは他者の情報を改ざんし、または無断で消去する行為。
</li><li>
当社または第三者になりすます行為（詐称を目的にメールヘッダ等の部分に細工を行う行為を含みます）
</li><li>
本人の同意を得ることなく、または詐欺的な手段（いわゆるフィッシングおよびこれに類する手段を含みます。）により他者のメンバー登録情報を取得する行為
</li><li>
本サービスの他の利用者のIDまたはパスワードを利用する行為
</li><li>
一人の利用者が複数のメールアドレスなどを登録して重複してメンバー登録を行う行為
</li><li>
登録メンバー資格を停止ないし無効にされた登録メンバーに代わりメンバー登録をする行為
</li><li>
当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
</li><li>
本サービスの他の利用者の情報の収集
</li><li>
当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
</li><li>
ストーキング行為その他の態様の如何を問わず、当社および本サービスの他のユーザーを含む第三者に対して嫌がらせをする行為（本サービス提供に必要な範囲を超えた連絡および明示的な連絡拒否に反した連絡をする行為を含みます。）
</li><li>
本規約または個別規定に抵触する行為
</li><li>
反社会的勢力などへの利益供与
</li><li>
面識のない異性との出会いを目的とした行為
</li><li>
法令に基づき監督官庁などへの届出、許認可の取得等の手続きが義務づけられている場合に、当該手続きを履行せずに本サービスを利用する行為
</li><li>
本サービスの運営を妨害する行為、他のユーザーまたは第三者が主導する情報の交換または共有を妨害する行為、信用の毀損または財産権の侵害などのように当社、ユーザー又は他者に不利益を与える行為
</li><li>
長時間の架電、同様の問い合わせの繰り返しを過度に行い、または義務や理由のないことを強要し、当社の業務に著しく支障をきたす行為
</li><li>
本サービスの範囲を超える役務提供を強要する行為
</li><li>
口コミサイトやブログに、ある商品又はサービスについて実際のものより著しく優良・有利であると誤認させる内容を記載し、又は記載することを依頼する行為
</li><li>
当社を介さないシッティングや支払い、および本サイトの販売⼿順を回避する⾏為、他の Web サイトや販売⼿順へ誘導する⾏為（プロフィール欄やメッセージ内で、URL やメールアドレスを書き込むなど）や直接取引を想起させる行為
</li><li>
レビューシステムの透明性・適切性を害する行為（不正または不適切なレビューを掲載し、相手方に対しレビューの掲載を強制しもしくはかかる掲載を行うよう脅迫し、またはレビューの掲載事項中に相手についての個人情報を掲載することはできません。また、レビュー内容を操作することは一切禁止されています。レビュー記入を依頼することは可能ですが、レビューの提供や削除の依頼にお金を支払うことやインセンティブを付与することは禁止します。 加えて、なりすまして投稿をすることや営利目的のレビューを投稿することも禁止されています）
</li><li>
前各号の行為を直接または間接に惹起し、または容易にする行為
</li><li>
前各号の行為を試みること
</li><li>
その他、当社が不適切と判断する行為
</li></ol>
<h2>第10条 監視業務  </h2>
<ol>
    <li>
当社は、ユーザーが本規約および個別規定に従った本サービスの利用をしているか、また、本規約および個別規定に反する行為や不正がないかなどを監視する業務を独自の裁量で行う権利を有するものとします。
</li><li>
本サービスで提供する「メッセージ機能」は、メッセージの送信者と受信者および当社の三者のみが閲覧できるようになっています。当社は前項の監視業務を含め本サービスの提供上必要な範囲に限り、かつ必要に応じて、その内容を閲覧し、ユーザーの本規約、および個別規約の違反行為または不正行為に対して登録の取消しその他の当社が適切と考える措置を行うことができるほか、本規約および個別規定に照らして一部または全てを削除することがあります。
</li></ol>
<h2>第11条 規約違反の対処及び違約金など</h2>
<ol>
    <li>
当社は、ユーザーの行為が本規約、および個別規定などに反すると判断した場合に、当社の判断により、当該ユーザーに何ら通知することなくして、本サービスの一時停止、メンバー登録の取消し、本サービスへのアクセスの拒否、当サイト上におけるプロフィールなどの掲載情報や電子掲示板への投稿の全部もしくは一部の削除、変更または公開範囲の制限などの必要な措置をとることができるものとします。
</li><li>
前項に基づく当社の対処に関する質問、苦情は一切受け付けておりません。なお、ユーザーは、当該措置によって被った一切の損害について、当社に対して賠償請求を行わないものとします。
</li><li>
当社は、ユーザーが本規約および個別規定違反などの悪質な行為を行っていると判断した場合、当該ユーザーに対して法的措置を検討するものとします。
</li><li>
ユーザーは、ユーザーが本規約および個別規定違反などの行為を行ったことにより当社に損害（第三者に損害が生じ、その損害について当社が填補した場合を含む）が生じた場合、その一切の損害について、当社に対して賠償する責任を負うものとします。
</li><li>
登録メンバーまたは登録メンバーであった者が、第9条第31項に違反し、本サービスを介さずに直接取引（直接取引を誘引した場合、または直接取引の誘因に応じた場合を含む）をした場合には、当該登録メンバーまたは登録メンバーであった者は、前項に定める損害賠償金とは別に、違反1回につき30万円を支払うものとします。
<h2>第12条 個人情報の保護</h2>
当社は、個人情報およびそれに類する情報を「プライバシーポリシー(個人情報保護方針。別ページに制定)」に基づき、適切に取り扱うものとし、ユーザーはプライバシーポリシーに同意するものとします。
</li></ol>
<h2>第13条 免責</h2>
<ol>
    <li>
メンバー登録の取消し、ユーザーからのID・パスワードの第三者への漏洩、ユーザーによる秘密漏示、本サービスのシステム不具合や障害・中断やデータの消失・漏洩等により生じた不利益・損害など、本サービスの利用によりユーザーに生じた一切の不利益・損害について当社は一切の責任を負いません。
</li><li>
ユーザーが、本サービスを利用することにより、他のユーザーまたは第三者に対し不利益・損害を与えた場合、利用者は自己の費用と責任においてこれを賠償するものとし、これらの一切の不利益・損害について当社は一切責任を負いません。
</li><li>
本サービス上でやりとりされるメッセージや送受信されるファイルに個人情報などが含まれていた場合、それによって登録メンバーが被った不利益・損害について、当社は一切責任を負いません。
</li><li>
当社は、登録メンバーがプロフィールやポートフォリオなど、または電子掲示板に掲載または投稿した画像、テキスト・プログラムなど、本サービス上でメンバーが作成・登録・提供・掲載・投稿した一切の画像・テキスト・プログラムなどについて、当サイトの円滑な運営または本サービスの継続的な提供のために必要な範囲内で、当社の判断により、変更・削除その他の改変を行うことができるものとし、これらによる一切の不利益・損害について当社は一切責任を負いません。
</li><li>
登録メンバーのユーザーIDとパスワードを使用して当サイトが利用された場合、当社は当該登録メンバーによる利用とみなします。
</li><li>
当社はペットシッター選定に際して所定の確認作業を行っていますが，ペットシッターの能力、品質などに対して責任を負うものではありません。
</li></ol>
<h2>第14条 本規約および個別規定または当サイトの変更・中止  </h2>
<ol>
    <li>
当社は、システム障害および保守、停電や火災などの天変地異、その他技術上・運営上の理由などにより、本サービスの提供が困難であると判断した場合、ユーザーへの事前通知を行わず、本サービスの中断を行う場合があります。
</li><li>
当社は、本規約または個別規定を利用者に事前通知することなく変更することができます。変更後の本規約または個別規定は、別途定める場合を除いて、本サイト上に表示された時点より効力を生じるものとします。利用者が、本規約または個別規定の変更後に本サイトまたは本サービスを利用したときに、当該変更に同意したものとみなされ、変更後の本規約および個別規定が適用されるものとします。当社は、当社が必要と判断した場合には、利用者に通知することなく、いつでも本サイトまたは本サービスを変更・停止・中止または終了することができるものとし、これらにより、利用者に生じた不利益又は損害について、一切責任を負わないものとします。本サイトまたは本サービスが変更・停止・中止または終了した場合であっても、これらの事象が生じる前に利用者間で有効に成立したシッティングに関する契約は何ら影響を受けないものとします。
</li></ol>
<h2>第15条 通知又は連絡</h2>
当社が登録メンバーへの連絡または通知の必要がある場合には、登録されたメールアドレス宛へのメール、登録された電話番号への連絡、または登録された住所宛に郵送することによって、連絡または通知を行います。連絡または通知は、当社による発信時にその効力を有するものとします。
<h2>第16条 準拠法・管轄裁判所 </h2>
<ol>
    <li>
本規約に関する準拠法は、日本法とし、本規約の一部が無効な場合でも、適用可能な項目については効力があるものとします。
</li><li>
当サイトまたは本サービスに関連して訴訟などの必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
</li></ol>
<h2>附則 </h2>
1.
2024年3月20日 施行
          </div>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default RegurationApp
