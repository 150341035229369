import { useState } from 'react';
import SitterActions from './SitterActions';

function useSitterState() {

  const [item, setItem] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)
  const [list, setList] = useState(null)
  const [error, setError] = useState(null)

  const listLoadedHandler = (data) => {
    setList(data.data)
    setUpdatedAt(new Date())
  }
  const itemLoadedHandler = (data) => {
    setItem(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  } 

  const getItem = (token, id) => {
    SitterActions.getItem(token, id, itemLoadedHandler, errorHandler)
  }

  const getList = ( target_date = null , area = null, name = null) => {

    SitterActions.getList(target_date, area, name, listLoadedHandler, errorHandler)
  }

  const getListByLocation = ( lat, lng, radius) => {

    SitterActions.getListByLocation(lat, lng, radius, listLoadedHandler, errorHandler)
  }

  const getMainImageFromMedias = (medias) => {
    if(medias)
      return medias.find(item => item.category === "main")
    else 
      return null
  }

  return{ 
    sitter:item,
    siter_updated_at:updated_at,
    sitter_list:list, sitter_error:error, 
    getSitterList:getList,
    getSitterListByLocation:getListByLocation,
    getSitter:getItem,
    getMainImageFromMedias
  };
}

export default useSitterState