
import 'whatwg-fetch'



const Error = {


  
  authError(){
   // LoginAction.logout()
    //window.location.href = "/login"
  }
 
}

export default Error
