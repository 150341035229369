

import Action from "../action/Action";
import config from 'react-global-configuration';

var SitterActions = {

  getList(target_date, area, name, callback, errorHandler){

         
    let url_params = new URLSearchParams();
    if(target_date)
      url_params.set("target_date",target_date )
    if(area)
      url_params.set("area",area )
    if(name)
      url_params.set("word",name )
   
    const query_string =  url_params.toString()
    const url = config.get("API_PATH")  + config.get("API_SITTER") + "?" + query_string

    Action.fetchJson( url , "GET", {}, null,  callback, errorHandler) 
   
  },


  getListByLocation(lat, lng, radius, callback, errorHandler){

         
    let url_params = new URLSearchParams();
    url_params.set("lat",lat )
    url_params.set("lng",lng )
    url_params.set("radius",radius )
       
    const query_string =  url_params.toString()
    const url = config.get("API_PATH")  + config.get("API_SITTER") + "?" + query_string

    Action.fetchJson( url , "GET", {}, null,  callback, errorHandler) 
   
  },


  getItem(token, id,callback, errorHandler){

    const url = config.get("API_PATH")  + config.get("API_SITTER")  + "/" + id
    if(token){  
      Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
    }else{
      Action.fetchJson( url , "GET", {}, null,  callback, errorHandler)
    }
    
  },



}

export default SitterActions
