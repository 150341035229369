
import BookProps from './BookProps';
import { format } from 'date-fns'
const props = extendValues => class extends BookProps {};
class Book extends props({}) {

  createFormData(){
    return {
      "name": this.name,
      "sitter_id":this.sitter_id,
      "start_at": this.start_at ? format(this.start_at, "yyyy-MM-dd HH:00:00") : null,
      "end_at": this.end_at ? format(this.end_at, "yyyy-MM-dd HH:00:00")  : null,
      "pets":this.pets,
      "memo":this.message
    }
  }
}
export default Book
