
import ProfileProps from './ProfileProps';

const props = extendValues => class extends ProfileProps {};
class Profile extends props({}) {

  /**getLatestReportMonthDate(){
    const today = new Date()
    return today
  }**/

}
export default Profile
