import React,{  } from 'react';
import PropTypes from 'prop-types';
import Book from './Book.tsx';
import Pet from "../pet/Pet.tsx"
import "./book.css"

export const BookCard = ({
  book,
  card_type,
  pets,
  changeHandler
}) => {

  
  const clickCardHandler = () => {
    //window.location.href = "/sitter/" + sitter.id
  }


  const changePetHandler = (e) => {
    const index = book.pets.findIndex( id => id === e.target.value)
    const _pets = book.pets
    if(index === -1){
      _pets.push(e.target.value)
    }else{
      _pets.splice(index,1)
    }
    changeHandler("pets", _pets)
  }

  const changeMessageHandler = (e) => {
    changeHandler("message", e.target.value)
  }

  return(
    book ? 
    <div className="book-card" onClick={clickCardHandler}>
      <div className="card-item"><label>予約者名</label><div className="value">{book.name}</div></div>
      <div className="card-item"><label>お預けするペット</label> <div className="value pet-list">{
        card_type === "edit" && pets ? pets.map(pet => {
          const checked = book.pets.findIndex( id => id === pet.id ) === -1 ? null : true
          return(<div className="pet-list-item"><input type="checkbox" onChange={changePetHandler} value={pet.id} checked={checked} />{pet.name} </div>)
        }) 
        : null
        }</div>
      </div>
      <div className="card-item"><label>依頼内容</label> <div className="value">{
        card_type === "edit" ? <textarea value={book.message} onChange={changeMessageHandler}/>
        : null}
      </div> </div>
      </div> : null
   )
}

BookCard.propTypes = {
  book:Book,
  card_type:PropTypes.string | null,
  pets:PropTypes.arrayOf(Pet) | null,
  changeHandler:PropTypes.func | undefined
};

BookCard.defaultProps = {
  book:{},
  card_type:"default",
  pets:null,
  changeHandler: undefined
};
