import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import './footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import useAuthState from '../auth/useAuthState';

export const Footer = ({
  type, selected
}) => {
  const {token, auth}  = useAuthState()
  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const today = new Date()
  
  const moveTo = (page) => {
    window.location.href = page
  }
  return(
    <div>
      <footer className="pc">
          <div className="link">
            <div><a href="/company">会社概要</a></div>
            <div><a href="/privacy">プライバシーポリシー</a></div>
            <div><a href="/ec">特定商取引法に基づく表記</a></div>
            <div><a href="/regulation">サービス利用規約</a></div>
          </div>
          <div className="copyright">©{today.getFullYear()} YOKUSURU Inc.</div>
      </footer> 
      <footer className="mob">
         <div className="mob-item" onClick={() => moveTo("/home")} ><div className="icon"><FontAwesomeIcon icon={faHouse} size="2x" /></div><div className="label">トップ</div></div>
         <div className="mob-item" onClick={() => moveTo("/search")}><div className="icon"><FontAwesomeIcon icon={faMagnifyingGlass} size="2x"/></div><div className="label">検索</div></div>
         {
          token ? <div className="mob-item" onClick={() => moveTo("/mypage")}><div className="icon"><FontAwesomeIcon icon={faUser} size="2x"/></div><div className="label">マイページ</div></div>

          :<div className="mob-item" onClick={() => moveTo("/login")}><div className="icon"><FontAwesomeIcon icon={faRightToBracket} size="2x"/></div><div className="label">ログイン</div></div>
         }
         
         <div className="mob-item" onClick={() => moveTo("/menu")}><div className="icon"><FontAwesomeIcon icon={faBars} size="2x" /></div><div className="label">メニュー</div></div>
         
      </footer> 
    </div>
);}

Footer.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.string | null
};

Footer.defaultProps = {
  type: "small",
  selected : null
};
