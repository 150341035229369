import React, { useState, useEffect } from 'react'

import { CloseBtn } from '../stories/closebtn/CloseBtn';
import useAuthState from '../auth/useAuthState';
import useProfileState from '../profile/useProfileState';
import "./login.css"

function LoginBox(props:PropsValue) {


  const {token, loginError, login} = useAuthState()
  const [msessage, setMessage] = useState(null)
  const [username, setUserName] = useState(null)
  const [password, setPassword] = useState(null)

  const {profile, profile_error, getProfile} = useProfileState()
  
  useEffect(() => {
    if(token ){
      getProfile(token)
    }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  useEffect(() => {
    
    if(profile ){
     props.authHandler(token)
    } 
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);


  useEffect(() => {
    
    if(profile_error ){
      window.location.href = "/profile"
    } 
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile_error]);


  useEffect(() => {
    
    if(loginError){
      setMessage("IDまたはパスワードが一致しません。")
    } 
   
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loginError]);

  const loginHandler = () => {
    setMessage(null)
    login(username, password)
  }


  const closeOverLay = () => {
    if(props.closeHander){
      props.closeHander()
    }
  }

  return(

    <div className="login-box">
      <div className="overlay"></div>
      <div className="wrap">
      <CloseBtn onClick={closeOverLay} />
        <div className="login upper">
          <div className="login-item">
              <label>メールアドレス</label>
              <div className="input"><input  type="text" name="username" placeholder="" label="メールアドレス" value={username}  onChange={(e) => setUserName(e.target.value)} /></div>
          </div>
          <div className="login-item" >
            <label>パスワード</label>
            <div className="input"><input type="password" name="password" placeholder="" label="パスワード" value={password}     onChange={(e) => setPassword(e.target.value)} /></div>
          </div> 
          <div className='message'>{msessage}</div>
          <div className="login-btn" ><button onClick={loginHandler} className="progress big">ログイン</button></div>
          <div className="new-user" ><a href="/user/create">新規ユーザー登録</a></div>
        </div>
      </div>
  </div>

  )
}

export default LoginBox


/*
 <main  className="innner">
        
        <Footer />
    </main>
    */