
import 'whatwg-fetch'
import Action from "../action/Action"
import config from 'react-global-configuration';


const AccountAction =  {

  create(loginname,password,callback, errorHandler){

    const item = {
      account_id:loginname,
      account_type:"password",
      mail:loginname,
      password:password,
      user_type:"user"
    }
  
    Action.fetchJson( config.get("API_PATH") + "/accounts/user"  , "POST", {"Content-Type":"application/json"}, JSON.stringify(item),  callback, errorHandler)
  },


  check(mail,secret,callback, errorHandler){
    Action.fetchJson( config.get("API_PATH") + "/accounts/" + mail + "/" + secret  , "GET", {}, null,  callback, errorHandler)
  },

  

   
}

export default AccountAction
