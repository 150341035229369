import React, {  } from 'react'

import Header from "../header/Header"
import { Footer } from '../footer/Footer';

import "../styles/form.css"
import "../styles/centerBox.css"

function AccountMailCheckSuccessApp(props) {


  const loginHandler = () => {
    window.location.href = "/login"
  }

  return(


      <div className="top">
        <Header />
        <main  className="innner">
          <div className="center-box">

            <h1 >新規会員登録</h1>
            <div>メールアドレスが認証されました。 <br/>ログインして登録を継続してください。</div>
            <div className="progress-btn"><button onClick={loginHandler} className="progress big" >ログイン</button></div>
            </div>

        <Footer />
      </main>
      </div>


  )
}

export default AccountMailCheckSuccessApp
