import React, { useState, useEffect } from 'react'
import useAccoountState from './useAccoountState'
import Header from "../header/Header"
import { Footer } from '../footer/Footer';

import "../styles/form.css"
import "../styles/centerBox.css"

function AccountCreateApp(props) {

  const [msessage, setMessage] = useState(null)
  const [username, setUserName] = useState(null)
  const [password, setPassword] = useState(null)
  
  const {account,accountError,createAccount} = useAccoountState()
  


  useEffect(() => {
    
    if(account){
      window.location.href = "/user/create/success"
    }
   
  },[account]);


  useEffect(() => {
    
    if(accountError){
      setMessage(<div className='message'>登録エラーです。<br/>すでに登録済みのメールアドレスの可能性があります。<br/>ご確認ください。</div>)
    } 
   
   
  },[accountError]);

  const createHandler = () => {
    setMessage(null)
    createAccount(username, password)
  }

  return(

    
    <div className="top">
      <Header />
      <main  className="innner">
        <div className="center-box">
          <h1 >新規会員登録</h1>
          <div className="form-item">
            <label>メールアドレス</label>
            <div className="input"><input type="text" name="mail" value={username}  onChange={(e) => setUserName(e.target.value)}  placeholder='メールアドレス'/></div>
          </div>
          <div className="form-item">
            <label>パスワード</label>
            <div className="input"><input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}  placeholder='パスワード'/></div>
          </div>
          <div className='message'>{msessage}</div>
          <div className="progress-btn" ><button onClick={createHandler} className="progress big">登録</button></div>
        
        </div>
        <Footer />
      </main>
    </div>


  )
}

export default AccountCreateApp
