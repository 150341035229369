import React, {  } from 'react'
import Header from "../header/Header"
import { Footer } from '../footer/Footer';

import "../styles/form.css"
import "../styles/centerBox.css"

function AccoutCreateSuccessApp(props) {




  return(

    <div className="top">
      <Header />
      <main  className="innner">
         <div className="center-box">
 
          <h1 >新規会員登録</h1>
            <div>登録したメールアドレスに、確認用URLをお送りしました。 <br/>URLをクリックして登録を進めてください。</div>
          </div>
   
        <Footer />
      </main>
    </div>



   
  )
}

export default AccoutCreateSuccessApp
