import React,{ useEffect, useState  } from 'react';
import Book from './Book.tsx';


export const BookSitterItem = ({
  book
}) => {

  const [item, setItem] = useState(null)



  useEffect(() => {
   
    if(book){
      setItem(book)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[book]);




  return(
    item ? 
      <div className="book-items">
        <div className="book-item"><label>シッター名</label><div className="value">{item ? item.sitter.profile.name : null }</div></div>
        <div className="book-item"><label>TEL</label><div className="value">{item ? item.sitter.profile.tel : null }</div></div>
        <div className="book-item"><label>メールアドレス</label><div className="value">{item ? item.sitter.profile.mail : null }</div></div>

        
        
      </div>
    : null
   )
}

BookSitterItem.propTypes = {
  book:Book,

};

BookSitterItem.defaultProps = {
  book:null,
};

