import React, {  } from 'react';
import Pet from './Pet.tsx';
import "./pet.css"


/**
 * Primary UI component for user interaction
 */
export const PetIcon = ({  pet,  ...props }) => {




  return (
    <div className="peticon" id={"pettticon" + pet ? pet.id: "0"} key={"pettticon" + pet ? pet.id: "0"}>
      {
        pet && pet.photo && pet.photo.length > 0 ? <div className="pet-icon-small"><img src={pet.photo[0].url} alt="ペット" /></div> : null
      }
     
    </div>
  );
};

PetIcon.propTypes = {

  pet:Pet,

};

PetIcon.defaultProps = {
  pet: new Pet(),
};
